import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner";
import BuyDialog from "components/Customer/BuyDialog";
import { publicBusinessPageFetchDetailsSaga } from "../state/actions";
import { MenuType } from "enums/Menu";
import { buyDialogSetConfig } from "components/Customer/BuyDialog/state/actions";
import Images from "assets/images";
import { truncateString } from "utils/string";

type Props = {
  storeName: string | null;
};

export default function MerchantCommunityCart(props: Props) {
  const { storeName } = props;

  const dispatch = useDispatch();

  const { isLoading, communityCart } = useSelector(
    (store: any) => store?.publicBusinessPage
  );

  const { open: shouldShowBuyDialog } = useSelector(
    (store: any) => store?.buyDialog
  );

  const handleOpenBuyDialog = (product: any) => {
    dispatch(
      buyDialogSetConfig({
        open: true,
        product,
        menuType: MenuType.COMMUNITY_CART,
      })
    );
  };

  const handleCloseBuyDialog = () => {
    dispatch(buyDialogSetConfig({ open: false }));
  };

  useEffect(() => {
    const payload: any = {
      type: MenuType.COMMUNITY_CART,
    };

    if (storeName) {
      payload.store = storeName;
    }

    dispatch(publicBusinessPageFetchDetailsSaga(payload));
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-4">
            {communityCart?.map((elem: any) => (
              <div
                key={elem?._id}
                className="flex justify-between items-center bg-white border-[1px] border-solid border-[#e7e7e7] pl-4 rounded-md mt-4 cursor-pointer"
                onClick={() => handleOpenBuyDialog(elem?.product)}
              >
                <div className="md:w-3/5 flex flex-col justify-between">
                  <div className="my-2">
                    <div className="text-grey text-sm font-semibold">
                      {elem?.product?.title}
                    </div>

                    <div className="text-grey text-sm font-normal mt-2">
                      {truncateString(elem?.product?.description, 50)}
                    </div>

                    <div className=" text-grey text-sm mt-2">
                      ${elem?.product?.variations[0]?.price}
                    </div>
                  </div>
                </div>

                <img
                  src={elem?.product?.images[0] || Images.NoImagePlaceholder}
                  className="w-32 h-32 rounded-r-md object-cover"
                />
              </div>
            ))}
          </div>

          {shouldShowBuyDialog && <BuyDialog onClose={handleCloseBuyDialog} />}
        </div>
      )}
    </div>
  );
}
