import React from "react";
import Button from "components/Button";
import { Document, Page, pdfjs } from "react-pdf";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Dialog, DialogTitle } from "components/Dialog";
import terms from "assets/terms.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type Props = {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
};

export default function TermsOfServiceDialog({
  open,
  onClose,
  onAccept,
}: Props) {
  const [numPages, setNumPages] = React.useState(null);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      disableEscapeKeyDown
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle onClose={onClose}>Terms Of Service</DialogTitle>

      <DialogContent dividers>
        <div className="w-full">
          {/* eslint-disable-next-line */}
          <Document file={terms} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            ))}
          </Document>
        </div>
      </DialogContent>

      <DialogActions>
        <div className="flex my-2 mr-4">
          <div className="mr-8">
            <Button
              label="Decline"
              color="primary"
              onClick={onClose}
              style={{
                borderRadius: 4,
                fontSize: 14,
                color: "#FFFFFF",
                height: 40,
                width: 150,
              }}
            />
          </div>

          <div>
            <Button
              label="Accept"
              color="secondary"
              onClick={onAccept}
              style={{
                borderRadius: 4,
                fontSize: 14,
                color: "#FFFFFF",
                height: 40,
                width: 150,
              }}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}
