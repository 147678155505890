import { useSelector } from "react-redux";
import { QRCodeCanvas } from "qrcode.react";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Tooltip from "@mui/material/Tooltip";
import TextField from "components/TextField";
import Button from "components/Button";
import { getMerchantStoreUrl, getCommunitySignupUrl } from "utils/common";

export default function MerchantSettingsShare() {
  const { user } = useSelector((store: any) => store?.merchantAuth);

  const storeUrl = getMerchantStoreUrl(user?.merchant?.subdomain);

  const signupUrl = getCommunitySignupUrl(user?.merchant?.subdomain);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(storeUrl);
  };

  const handleDownloadQRCode = (codeId: string) => {
    const canvas = document.getElementById(codeId);

    const image = canvas // @ts-ignore
      ?.toDataURL("image/png")
      .replace("image/png", "image/octet-stream");

    const link = document.createElement("a");

    link.download = `${codeId}.png`;
    link.href = image;
    link.click();
  };

  return (
    <div>
      <div className="flex items-center">
        <div className="w-1/2">
          <TextField
            fullWidth
            variant="outlined"
            name="email"
            value={storeUrl}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            disabled
          />
        </div>

        <div className="ml-4">
          <Tooltip title="Copy Link" placement="right">
            <IconButton onClick={handleCopyLink}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className="my-8">
        <div className="mb-2 text-grey text-lg font-medium">
          Get the QR code
        </div>
        <div className="mb-8 text-grey text-sm">
          Scan the code to launch your store. Works online and offline (though
          you&apos;ll need a printer, obviously).
        </div>

        <div className="w-1/2 flex justify-between">
          <div>
            <div className="text-grey text-lg font-medium mb-4">Menu</div>

            <div className="mb-8">
              <QRCodeCanvas
                id="store-qr-code"
                value={storeUrl}
                size={128}
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="L"
                includeMargin={false}
                style={{
                  height: 200,
                  width: 200,
                }}
              />
            </div>

            <Button
              label="Download QR Code"
              color="info"
              onClick={() => handleDownloadQRCode("store-qr-code")}
              style={{
                borderRadius: 4,
                fontSize: 14,
                color: "#FFFFFF",
                height: 40,
              }}
            />
          </div>

          <div>
            <div className="text-grey text-lg font-medium mb-4">
              Grow your Community
            </div>

            <div className="mb-8">
              <QRCodeCanvas
                id="community-signup-qr-code"
                value={signupUrl}
                size={128}
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="L"
                includeMargin={false}
                style={{
                  height: 200,
                  width: 200,
                }}
              />
            </div>

            <Button
              label="Download QR Code"
              color="info"
              onClick={() => handleDownloadQRCode("community-signup-qr-code")}
              style={{
                borderRadius: 4,
                fontSize: 14,
                color: "#FFFFFF",
                height: 40,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
