import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Button from "components/Button";
import CreateRolloverDialog from "components/CreateRolloverDialog";
import { useMergeState } from "utils/custom-hooks";
import {
  merchantUpdateSettingsMenu,
  getMerchantPlanRollovers,
  createMerchantPlanRollover,
  updateMerchantPlanRollovers,
} from "api";
import { appSetError } from "app/state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";

const StyledTableCell = styled(TableCell)(() => ({
  // padding: 0,
}));

export default function MerchantSettingsPlans() {
  const dispatch = useDispatch();

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const [state, setState] = useMergeState({
    onlyShowPlans: false,
    hideOneTimePurchases: false,
    shouldShowCorporatePlans: false,
    shouldShowCreateRolloverDialog: false,
    isSavingRollover: false,

    planRollovers: [],
  });

  const handleChange = async (event: any, key: string) => {
    setState({ [key]: event.target.checked });
    await merchantUpdateSettingsMenu({ [key]: event.target.checked });
  };

  const handleOpenCreateRolloverDialog = (selectedPlanRollover = null) => {
    setState({
      shouldShowCreateRolloverDialog: true,
      selectedPlanRollover,
    });
  };

  const handleCloseCreateRolloverDialog = () => {
    setState({
      shouldShowCreateRolloverDialog: false,
    });
  };

  const init = async () => {
    try {
      const response = await getMerchantPlanRollovers();

      setState({
        planRollovers: response?.data,
      });
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  const handleSaveRollover = async (payload: any) => {
    try {
      handleCloseCreateRolloverDialog();

      let response = null;

      if (payload?.id) {
        response = await updateMerchantPlanRollovers(payload);
      } else {
        response = await createMerchantPlanRollover(payload);
      }

      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );

      await init();
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  useEffect(() => {
    init();

    setState({
      onlyShowPlans: user?.merchant?.onlyShowPlans,
      hideOneTimePurchases: user?.merchant?.hideOneTimePurchases,
      shouldShowCorporatePlans: user?.merchant?.shouldShowCorporatePlans,
    });
  }, []);

  return (
    <div>
      <div className="flex items-center">
        <div className="w-1/2">
          <div>
            <span>Only show plans in menu</span>
            <Switch
              checked={state?.onlyShowPlans}
              onChange={(event: any) => handleChange(event, "onlyShowPlans")}
            />
          </div>

          <div className="my-4">
            <span>Hide one-time purchases from products</span>
            <Switch
              checked={state?.hideOneTimePurchases}
              onChange={(event: any) =>
                handleChange(event, "hideOneTimePurchases")
              }
            />
          </div>

          <div className="my-4">
            <span>Allow purchasing corporate plans</span>
            <Switch
              checked={state?.shouldShowCorporatePlans}
              onChange={(event: any) =>
                handleChange(event, "shouldShowCorporatePlans")
              }
            />
          </div>

          <hr className="my-8" />

          <div>
            <div className="flex justify-between items-center">
              <div className="text-xl font-semibold">Plan Rollovers</div>
              <Button
                label="Create Rollover"
                color="secondary"
                onClick={handleOpenCreateRolloverDialog}
                style={{
                  borderRadius: 10,
                  fontSize: 14,
                  color: "#FFFFFF",
                  height: 50,
                }}
              />
            </div>

            <div className="mt-8">
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell component="th" scope="row">
                        Sr.
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        Name
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        Actions
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflow: "visible" }}>
                    {state?.planRollovers?.map(
                      (planRollover: any, index: number) => (
                        <TableRow key={planRollover._id}>
                          <StyledTableCell component="th" scope="row">
                            <span className="text-grey">{index + 1})</span>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <span className="text-grey">
                              {planRollover?.name}
                            </span>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <IconButton
                              onClick={() =>
                                handleOpenCreateRolloverDialog(planRollover)
                              }
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </StyledTableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>

      {state?.shouldShowCreateRolloverDialog && (
        <CreateRolloverDialog
          open={state?.shouldShowCreateRolloverDialog}
          onClose={handleCloseCreateRolloverDialog}
          onSave={handleSaveRollover}
          selectedPlanRollover={state?.selectedPlanRollover}
        />
      )}
    </div>
  );
}
