import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import MerchantSettingsGeneral from "./general";
import MerchantSettingsBusinessProfile from "./business-profile";
import MerchantSettingsPaymentProcessors from "./payment-processors";
import MerchantSettingsPlans from "./plans";
import MerchantSettingsShare from "./share";
import { useMergeState } from "utils/custom-hooks";

const SETTINGS_TABS_TYPE = {
  GENERAL: "GENERAL",
  PAYMENT_PROCESSORS: "PAYMENT_PROCESSORS",
  BUSINESS_PROFILE: "BUSINESS_PROFILE",
  PLANS: "PLANS",
  SHARE: "SHARE",
};

const SETTINGS_TABS = [
  // {
  //   label: "General",
  //   value: SETTINGS_TABS_TYPE.GENERAL,
  // },
  {
    label: "Business Profile",
    value: SETTINGS_TABS_TYPE.BUSINESS_PROFILE,
  },
  {
    label: "Payment Processors",
    value: SETTINGS_TABS_TYPE.PAYMENT_PROCESSORS,
  },
  {
    label: "Menu",
    value: SETTINGS_TABS_TYPE.PLANS,
  },
  {
    label: "Share",
    value: SETTINGS_TABS_TYPE.SHARE,
  },
];

export default function MerchantSettingsContainer() {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTabType = searchParams.get("tab");

  const [state, setState] = useMergeState({
    selectedTab: {},
  });

  const handleTabChange = (event: any, newTabType: number) => {
    setState({
      selectedTab: SETTINGS_TABS.find((elem: any) => elem.value === newTabType),
    });
  };

  useEffect(() => {
    let tab = "";

    if (state?.selectedTab?.value !== selectedTabType) {
      if (selectedTabType && !state?.selectedTab?.value) {
        tab = selectedTabType;
      } else if (state?.selectedTab?.value) {
        tab = state?.selectedTab?.value;
      } else {
        tab = SETTINGS_TABS_TYPE.BUSINESS_PROFILE;
      }

      const selectedTab =
        SETTINGS_TABS.find((elem) => elem.value === tab) || SETTINGS_TABS[0];

      setSearchParams({ tab });

      setState({ selectedTab });
    }
  }, [state?.selectedTab]);

  return (
    <div>
      <div className="text-4xl font-semibold text-grey mb-4">Settings</div>

      {state?.selectedTab?.value && (
        <div className="mt-8">
          <Tabs value={state?.selectedTab?.value} onChange={handleTabChange}>
            {SETTINGS_TABS.map((tab) => (
              <Tab
                key={tab.label}
                label={tab.label}
                value={tab.value}
                style={{
                  textTransform: "none",
                  color: "#363333",
                  fontSize: 16,
                  fontFamily: "Poppins",
                }}
              />
            ))}
          </Tabs>
        </div>
      )}

      <div className="my-10">
        {/* {state?.selectedTab?.value === SETTINGS_TABS_TYPE.GENERAL && (
          <MerchantSettingsGeneral />
        )} */}

        {state?.selectedTab?.value === SETTINGS_TABS_TYPE.BUSINESS_PROFILE && (
          <MerchantSettingsBusinessProfile />
        )}

        {state?.selectedTab?.value ===
          SETTINGS_TABS_TYPE.PAYMENT_PROCESSORS && (
          <MerchantSettingsPaymentProcessors />
        )}

        {state?.selectedTab?.value === SETTINGS_TABS_TYPE.PLANS && (
          <MerchantSettingsPlans />
        )}

        {state?.selectedTab?.value === SETTINGS_TABS_TYPE.SHARE && (
          <MerchantSettingsShare />
        )}
      </div>
    </div>
  );
}
