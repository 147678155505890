import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import AddNewCardDialog from "components/Customer/AddNewCardDialog";
import StripeNextActions from "components/Customer/StripeNextActions";
import Button from "components/Button";
import Spinner from "components/Spinner";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import CustomerCart from "components/Customer/Cart";
import ApplePay from "components/Customer/ApplePay";
import TermsOfServiceDialog from "components/TermsOfServiceDialog";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";
import {
  customerCheckoutCreateAccountSaga,
  customerCheckoutSetConfig,
  customerCheckoutGetPaymentMethodSaga,
} from "./state/actions";
import { customerAuthGenerateOTPSaga } from "../auth/state/actions"; // auth actions
import { customerAddNewCardDialogSetConfig } from "components/Customer/AddNewCardDialog/state/actions"; // add new card dialog actions
import {
  customerCartSetConfig,
  customerCartFetchTaxes,
} from "components/Customer/Cart/state/actions"; // cart actions
import { getCheckoutAmount } from "utils/common";

export default function CustomerCheckoutContainer() {
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((store: any) => store?.customerAuth);

  const [searchParams] = useSearchParams();

  const isCorporateAccount = searchParams.get("corporate") || false;

  const {
    details: { paymentMethods },
    checkoutConfig: { isCheckoutFormLoading, paymentMethodId, requiresAction },
  } = useSelector((store: any) => store?.customerCheckout);

  const {
    config: { open: shouldShowAddNewDialog },
  } = useSelector((store: any) => store?.customerAddNewDialog);

  const {
    config: { merchant },
    items,
    taxes,
  } = useSelector((store: any) => store?.customerCart);

  const [state, setState] = useMergeState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",

    // for corporate account
    companyName: "",

    // addressLine1: "",
    // addressLine2: "",
    // city: "",
    // state: "",
    // country: "US",
    // zipcode: "",

    otp: "",

    shouldVerifyOTP: false,
    resendOTPTimer: 0,

    shouldShowTermsOfServiceDialog: false,

    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.firstName) {
      payload = { firstName: true, ...payload };
      isValid = false;
    }

    if (!state.phone) {
      payload = { phone: true, ...payload };
      isValid = false;
    }

    if (!state.email) {
      payload = { email: true, ...payload };
      isValid = false;
    }

    if (isCorporateAccount && !state?.companyName) {
      payload = { companyName: true, ...payload };
      isValid = false;
    }

    // if (!state.addressLine1) {
    //   payload = { addressLine1: true, ...payload };
    //   isValid = false;
    // }

    // if (!state.city) {
    //   payload = { city: true, ...payload };
    //   isValid = false;
    // }

    // if (!state.state) {
    //   payload = { state: true, ...payload };
    //   isValid = false;
    // }

    // if (!state.zipcode) {
    //   payload = { zipcode: true, ...payload };
    //   isValid = false;
    // }

    if (state.shouldVerifyOTP && !state.otp) {
      payload = { otp: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleGenerateOTP = () => {
    setState({ shouldVerifyOTP: true, resendOTPTimer: 30 });

    dispatch(customerAuthGenerateOTPSaga({ phone: state.phone }));
  };

  const handleCreateAccount = () => {
    if (!isFormValid()) {
      return;
    }

    const payload: any = {
      firstName: state?.firstName,
      lastName: state?.lastName,
      phone: state?.phone,
      email: state?.email,
      // address: {
      //   addressLine1: state?.addressLine1,
      //   addressLine2: state?.addressLine2,
      //   city: state?.city,
      //   state: state?.state,
      //   country: state?.country,
      //   zipcode: state?.zipcode,
      // },
      otp: state?.otp,
    };

    if (isCorporateAccount) {
      payload.companyName = state?.companyName;
    }

    dispatch(customerCheckoutCreateAccountSaga(payload));
  };

  const handleOpenTermsOfServiceDialog = () => {
    if (!isFormValid()) {
      return;
    }

    setState({ shouldShowTermsOfServiceDialog: true });
  };

  const handleCloseTermsOfServiceDialog = () => {
    setState({ shouldShowTermsOfServiceDialog: false });
  };

  const handleAcceptTermsOfServiceDialog = () => {
    handleGenerateOTP();
    handleCloseTermsOfServiceDialog();
  };

  const handleReSendOTP = () => {
    if (state?.resendOTPTimer !== 0) {
      return;
    }

    setState({ resendOTPTimer: 30 });

    dispatch(customerAuthGenerateOTPSaga({ phone: state.phone }));
  };

  const handleOpenAddNewCardDialog = () => {
    dispatch(customerAddNewCardDialogSetConfig({ open: true }));
  };

  const handleCloseAddNewCardDialog = () => {
    dispatch(customerAddNewCardDialogSetConfig({ open: false }));
  };

  const handleSelectPaymentMethod = (paymentMethodId: string) => {
    dispatch(customerCheckoutSetConfig({ paymentMethodId }));
    dispatch(customerCartSetConfig({ open: true }));
  };

  const handleContinueToPayment = () => {
    dispatch(customerCartSetConfig({ open: true }));
  };

  useEffect(() => {
    const timerId =
      state.resendOTPTimer > 0
        ? setInterval(
            () => setState({ resendOTPTimer: state.resendOTPTimer - 1 }),
            1000
          )
        : undefined;

    return () => clearInterval(timerId);
  }, [state.resendOTPTimer]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(customerCheckoutGetPaymentMethodSaga());
    }
  }, []);

  useEffect(() => {
    if (merchant?._id) {
      dispatch(customerCartFetchTaxes({ mId: merchant?._id }));
    }
  }, [merchant]);

  return (
    <div>
      {state?.isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={state?.isLoading} />
        </div>
      ) : (
        <div className="flex justify-center">
          <div className="w-full p-4 md:px-12 md:py-4">
            <div className="flex flex-col items-center">
              <div className="w-full lg:w-2/3 border-[1px] border-solid border-[#f1f1f1] rounded-md p-4 my-2">
                <div
                  className={
                    isLoggedIn ? "flex justify-between items-center" : ""
                  }
                >
                  <div className="text-grey font-semibold text-lg">
                    1. Account Details
                  </div>
                  {isLoggedIn && <div className="text-grey">Logged In</div>}
                </div>

                {!isLoggedIn && (
                  <div className="mt-8">
                    <div className="flex flex-col md:flex-row justify-between items-center">
                      <div className="w-full md:w-1/2">
                        <TextField
                          fullWidth
                          label="FIRST NAME"
                          variant="outlined"
                          name="firstName"
                          value={state.firstName}
                          onChange={handleChange}
                          required
                          error={state?.errors?.firstName}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                          autoComplete="off"
                        />

                        {state?.errors?.firstName && (
                          <ErrorMessage message="First name is required" />
                        )}
                      </div>

                      <div className="w-full md:w-1/2 md:ml-2 mt-5 md:mt-0">
                        <TextField
                          fullWidth
                          label="LAST NAME"
                          variant="outlined"
                          name="lastName"
                          value={state.lastName}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                          autoComplete="off"
                        />
                      </div>
                    </div>

                    <div className="mt-4">
                      <TextField
                        fullWidth
                        label="EMAIL"
                        variant="outlined"
                        name="email"
                        value={state?.email}
                        onChange={handleChange}
                        required
                        error={state?.errors?.email}
                        InputLabelProps={{
                          shrink: true,
                          disableAnimation: true,
                        }}
                      />

                      {state?.errors?.email && (
                        <ErrorMessage message="Email is required" />
                      )}
                    </div>

                    {isCorporateAccount && (
                      <div className="mt-4">
                        <TextField
                          fullWidth
                          label="COMPANY NAME"
                          variant="outlined"
                          name="companyName"
                          value={state?.companyName}
                          onChange={handleChange}
                          required
                          error={state?.errors?.companyName}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                        />

                        {state?.errors?.companyName && (
                          <ErrorMessage message="Company name is required" />
                        )}
                      </div>
                    )}

                    {/* <div className="mt-4">
                      <TextField
                        fullWidth
                        label="ADDRESS LINE 1"
                        variant="outlined"
                        name="addressLine1"
                        value={state.addressLine1}
                        onChange={handleChange}
                        required
                        error={state?.errors?.addressLine1}
                        InputLabelProps={{
                          shrink: true,
                          disableAnimation: true,
                        }}
                      />

                      {state?.errors?.addressLine1 && (
                        <ErrorMessage message="Address line 1 is required" />
                      )}
                    </div>

                    <div className="mt-4">
                      <TextField
                        fullWidth
                        label="ADDRESS LINE 2"
                        variant="outlined"
                        name="addressLine2"
                        value={state.addressLine2}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                          disableAnimation: true,
                        }}
                      />
                    </div>

                    <div className="flex justify-between mt-4">
                      <div className="w-1/2">
                        <TextField
                          fullWidth
                          label="CITY"
                          variant="outlined"
                          name="city"
                          value={state.city}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                          required
                          error={state?.errors?.city}
                        />

                        {state?.errors?.city && (
                          <ErrorMessage message="City is required" />
                        )}
                      </div>

                      <div className="w-1/2 ml-2">
                        <TextField
                          fullWidth
                          label="STATE"
                          variant="outlined"
                          name="state"
                          value={state.state}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                          required
                          error={state?.errors?.state}
                        />

                        {state?.errors?.state && (
                          <ErrorMessage message="State is required" />
                        )}
                      </div>
                    </div>

                    <div className="flex justify-between mt-4">
                      <div className="w-1/2">
                        <TextField
                          fullWidth
                          label="ZIP CODE"
                          variant="outlined"
                          name="zipcode"
                          value={state.zipcode}
                          onChange={handleChange}
                          required
                          error={state?.errors?.zipcode}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                        />

                        {state?.errors?.zipcode && (
                          <ErrorMessage message="Zipcode is required" />
                        )}
                      </div>

                      <div className="w-1/2 ml-2">
                        <TextField
                          fullWidth
                          label="COUNTRY"
                          variant="outlined"
                          name="country"
                          value={state.country}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                          disabled
                        />
                      </div>
                    </div> */}

                    <div className="flex justify-between items-center mt-5">
                      <div className="w-full md:w-1/2">
                        <TextField
                          fullWidth
                          label="PHONE"
                          variant="outlined"
                          name="phone"
                          value={state.phone}
                          onChange={handleChange}
                          required
                          error={state?.errors?.phone}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                          }}
                          autoComplete="off"
                          type="number"
                          onWheel={(event: any) => event.target.blur()}
                          onInput={(event) => {
                            if (
                              // @ts-ignore
                              event?.target?.value
                            ) {
                              // @ts-ignore
                              event.target.value = Math.max(
                                0,
                                // @ts-ignore
                                parseInt(event.target.value, 10)
                              )
                                .toString()
                                .slice(0, 10);
                            }
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +1
                              </InputAdornment>
                            ),
                          }}
                        />

                        {state?.errors?.phone && (
                          <ErrorMessage message="Phone is required" />
                        )}
                      </div>

                      {state.shouldVerifyOTP && (
                        <div className="w-1/2 ml-2">
                          <TextField
                            fullWidth
                            label="OTP"
                            variant="outlined"
                            name="otp"
                            value={state.otp}
                            onChange={handleChange}
                            required
                            error={state?.errors?.otp}
                            InputLabelProps={{
                              shrink: true,
                              disableAnimation: true,
                            }}
                            autoComplete="off"
                            type="number"
                            onWheel={(event: any) => event.target.blur()}
                          />

                          {state?.errors?.otp && (
                            <ErrorMessage message="OTP is required" />
                          )}
                        </div>
                      )}
                    </div>

                    {state.shouldVerifyOTP && (
                      <div>
                        <div
                          className={`${
                            state.resendOTPTimer === 0
                              ? "text-grey"
                              : "text-grey-2"
                          }  text-sm mt-2 flex justify-end`}
                        >
                          <span
                            className={`${
                              state?.resendOTPTimer === 0
                                ? "underline cursor-pointer"
                                : ""
                            } mr-1`}
                            onClick={handleReSendOTP}
                          >
                            Resend OTP
                          </span>
                          {state?.resendOTPTimer > 1 && (
                            <span className="mr-1">
                              in {state?.resendOTPTimer}{" "}
                              {state?.resendOTPTimer > 1 ? "seconds" : "second"}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="mt-5">
                      <Button
                        label={`${
                          state.shouldVerifyOTP ? "Verify OTP" : "Generate OTP"
                        }`}
                        color="secondary"
                        onClick={
                          state.shouldVerifyOTP
                            ? handleCreateAccount
                            : handleOpenTermsOfServiceDialog
                        }
                        style={{
                          borderRadius: 10,
                          fontSize: 16,
                          color: "#FFFFFF",
                          height: 50,
                        }}
                        fullWidth
                        loaderButton
                        loadingPosition="center"
                        loading={isCheckoutFormLoading}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="w-full lg:w-2/3 border-[1px] border-solid border-[#f1f1f1] rounded-md p-4 my-2">
                <div className="text-grey font-semibold text-lg">
                  2. Payment Details
                </div>
                <div className="flex justify-center">
                  <div className="w-11/12">
                    {!!paymentMethods?.cards?.length && (
                      <div className="mt-8">
                        <div className="text-grey text-sm font-semibold">
                          Saved payment methods
                        </div>

                        {paymentMethods?.cards?.map((card: any) => (
                          <div key={card?.id} className="mt-4">
                            <div
                              className={`flex justify-between items-center h-16 cursor-pointer hover:bg-red-100 ${
                                paymentMethodId === card?.id ? "bg-red-100" : ""
                              }`}
                              onClick={() =>
                                handleSelectPaymentMethod(card?.id)
                              }
                            >
                              <div className="flex items-center text-grey">
                                <img src={Images.CaretRight} className="mx-2" />

                                <div className="text-lg">
                                  {String(card?.brand).toUpperCase()}
                                </div>

                                <div className="ml-4 text-sm">
                                  <div className="font-semibold">
                                    XXXX {card?.lastFourDigits}
                                  </div>{" "}
                                  <div>
                                    Expires ({card?.expiryMonth}/
                                    {card?.expiryYear})
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                        <hr className="my-4 border-4" />
                      </div>
                    )}

                    {isLoggedIn && (
                      <div className="mt-4">
                        <div className="text-grey text-sm font-semibold">
                          Add new payment method
                        </div>

                        <div className="px-4 mt-2">
                          <div>
                            <div
                              className="flex justify-between items-center h-16 cursor-pointer hover:bg-red-100"
                              onClick={handleOpenAddNewCardDialog}
                            >
                              <div className="text-grey font-semibold">
                                Credit/Debit Card
                              </div>
                              <img
                                src={Images.CaretRight}
                                className="w-4 h-4 mx-2"
                              />
                            </div>
                            <hr />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="md:hidden">
                  <div className="mt-4 text-grey text-sm font-medium">
                    <div className="flex justify-between items-center h-10">
                      <span className="text-grey text-lg font-medium">
                        Today&apos;s Charge
                      </span>
                      <span className="text-green-2 text-lg font-medium">
                        $
                        {parseFloat(
                          String(
                            getCheckoutAmount(
                              items,
                              taxes,
                              merchant?.processingFeesType
                            )
                          )
                        ).toFixed(2)}
                      </span>
                    </div>
                  </div>

                  <div className="mt-4">
                    <Button
                      label="Continue to Payment"
                      color="info"
                      onClick={handleContinueToPayment}
                      style={{
                        borderRadius: 10,
                        fontSize: 14,
                        color: "#FFFFFF",
                        height: 40,
                      }}
                      fullWidth
                    />
                  </div>

                  {isLoggedIn && (
                    <div className="mt-4">
                      <ApplePay />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="hidden md:block w-1/3 border-l-[1px]">
            <CustomerCart />
          </div>
        </div>
      )}

      {shouldShowAddNewDialog && (
        <AddNewCardDialog onClose={handleCloseAddNewCardDialog} />
      )}

      {requiresAction && <StripeNextActions />}

      {state.shouldShowTermsOfServiceDialog && (
        <TermsOfServiceDialog
          open={state.shouldShowTermsOfServiceDialog}
          onClose={handleCloseTermsOfServiceDialog}
          onAccept={handleAcceptTermsOfServiceDialog}
        />
      )}
    </div>
  );
}
