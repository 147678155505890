import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";
import {
  customerAuthGenerateOTPSaga,
  customerAuthVerifyOTPSaga,
} from "../state/actions";

export default function CustomerSigninContainer() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading } = useSelector((store: any) => store?.customerAuth);

  const [state, setState] = useMergeState({
    phone: "",
    otp: "",
    shouldVerifyOTP: false,
    resendOTPTimer: 0,
    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.phone) {
      payload = { phone: true, ...payload };
      isValid = false;
    }

    if (state.shouldVerifyOTP && !state.otp) {
      payload = { otp: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleGenerateOTP = () => {
    if (!isFormValid()) {
      return;
    }

    setState({ shouldVerifyOTP: true, resendOTPTimer: 30 });

    dispatch(customerAuthGenerateOTPSaga({ phone: state.phone }));
  };

  const handleVerifyOTP = () => {
    if (!isFormValid()) {
      return;
    }

    dispatch(
      customerAuthVerifyOTPSaga(
        {
          phone: state.phone,
          otp: state.otp,
        },
        navigate
      )
    );
  };

  const handleReSendOTP = () => {
    if (state?.resendOTPTimer !== 0) {
      return;
    }

    setState({ resendOTPTimer: 30 });
  };

  useEffect(() => {
    const timerId =
      state.resendOTPTimer > 0
        ? setInterval(
            () => setState({ resendOTPTimer: state.resendOTPTimer - 1 }),
            1000
          )
        : undefined;

    return () => clearInterval(timerId);
  }, [state.resendOTPTimer]);

  return (
    <div className="w-full min-h-screen flex justify-center items-center bg-red-light">
      <div className="bg-white opacity-90 rounded-lg w-11/12 md:w-3/5 lg:w-2/5 pt-5 pb-10 pl-10 pr-10 md:pl-20 md:pr-20 flex items-center mt-10 mb-10">
        <div className="w-full">
          <div className="flex justify-center">
            <img src={Images.CherryLogo} alt="Cherry" className="h-10 my-4" />
          </div>

          <div className="flex justify-center text-grey text-xl">
            Your homebase for local
          </div>

          <div className="mt-10">
            <div>
              <TextField
                fullWidth
                label="PHONE"
                variant="outlined"
                name="phone"
                value={state.phone}
                onChange={handleChange}
                required
                error={state?.errors?.phone}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                type="number"
                onWheel={(event: any) => event.target.blur()}
                onInput={(event) => {
                  if (
                    // @ts-ignore
                    event?.target?.value
                  ) {
                    // @ts-ignore
                    event.target.value = Math.max(
                      0,
                      // @ts-ignore
                      parseInt(event.target.value, 10)
                    )
                      .toString()
                      .slice(0, 10);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+1</InputAdornment>
                  ),
                }}
              />

              {state?.errors?.phone && (
                <ErrorMessage message="Phone is required" />
              )}
            </div>

            {state.shouldVerifyOTP && (
              <div className="mt-5">
                <TextField
                  fullWidth
                  label="OTP"
                  variant="outlined"
                  name="otp"
                  value={state.otp}
                  onChange={handleChange}
                  required
                  error={state?.errors?.otp}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  autoComplete="off"
                  type="number"
                  onWheel={(event: any) => event.target.blur()}
                />

                {state?.errors?.otp && (
                  <ErrorMessage message="OTP is required" />
                )}
              </div>
            )}

            {state.shouldVerifyOTP && (
              <div className="text-grey text-sm mt-2 flex justify-end">
                <span
                  className={`${
                    state?.resendOTPTimer === 0
                      ? "underline cursor-pointer"
                      : ""
                  } mr-1`}
                  onClick={handleReSendOTP}
                >
                  Resend OTP
                </span>
                {state?.resendOTPTimer > 1 && (
                  <span className="mr-1">
                    in {state?.resendOTPTimer}{" "}
                    {state?.resendOTPTimer > 1 ? "seconds" : "second"}
                  </span>
                )}
              </div>
            )}

            <div className="mt-10">
              <Button
                label={`${state.shouldVerifyOTP ? "Confirm Account" : "Login"}`}
                color="secondary"
                onClick={
                  state.shouldVerifyOTP ? handleVerifyOTP : handleGenerateOTP
                }
                style={{
                  borderRadius: 10,
                  fontSize: 16,
                  color: "#FFFFFF",
                  height: 50,
                }}
                fullWidth
                loaderButton
                loadingPosition="center"
                loading={isLoading}
              />
            </div>

            <div className="flex flex-col items-center mt-5">
              <div className="text-grey">OR</div>

              <div className="text-grey mt-5">
                Don&apos;t have an account?{" "}
                <Link to="/c/signup" className="underline">
                  Register here
                </Link>
              </div>
            </div>

            <div className="text-center text-grey mt-5">
              Sign in as merchant{" "}
              <Link to="/m/login" className="underline">
                here
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
