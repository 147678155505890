import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  getCustomerCart,
  createCustomerOrder,
  saveCustomerCart,
  getCustomerTaxes,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  CUSTOMER_CART_FETCH_SAGA,
  CUSTOMER_CART_BUY_SAGA,
  CUSTOMER_CART_SAVE_SAGA,
  CUSTOMER_CART_FETCH_TAXES_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import { customerCheckoutSetConfig } from "containers/consumer/checkout/state/actions"; // checkout actions
import {
  customerCartSetConfig,
  customerCartSetCart,
  customerCartSetTaxes,
} from "./actions";
import { getStoreName } from "utils/common";

function* customerCartFetchHandler(action: any): any {
  try {
    yield put(customerCartSetConfig({ isLoading: true }));

    const response = yield call(getCustomerCart, action.payload);

    if (response?.success) {
      yield all([
        yield put(customerCartSetCart(response?.data?.items)),
        yield put(
          customerCartSetConfig({
            cartId: response?.data?.cartId,
            merchant: response?.data?.merchant,
            isLoading: false,
          })
        ),
      ]);
    }
  } catch (error: any) {
    yield all([
      yield put(customerCartSetConfig({ isLoading: false })),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerCartSaveHandler(action: any): any {
  try {
    yield put(customerCartSetConfig({ isCheckoutLoading: true }));

    yield call(saveCustomerCart, action?.payload);

    if (action?.params?.shouldRedirect) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/checkout?id=${
        action?.payload?.id
      }&store=${getStoreName()}${
        action?.params?.isCorporatePlan ? "&corporate=true" : ""
      }`;
    }

    yield put(customerCartSetConfig({ isCheckoutLoading: false }));
  } catch (error: any) {
    yield all([
      yield put(customerCartSetConfig({ isCheckoutLoading: false })),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerCartBuyHandler(action: any): any {
  try {
    yield put(customerCartSetConfig({ isCheckoutLoading: true }));

    const response = yield call(createCustomerOrder, action.payload);

    if (response?.data?.requiresAction) {
      yield all([
        put(
          customerCartSetConfig({
            isCheckoutLoading: false,
          })
        ),
        put(
          customerCheckoutSetConfig({
            requiresAction: true,
            clientSecret: response?.data?.clientSecret,
          })
        ),
      ]);

      return;
    }

    if (response?.success) {
      // only required for apple pay
      action?.event?.complete("success");

      action?.navigate(`/orders/details?id=${response?.data?.id}`);

      yield all([
        yield put(
          customerCartSetConfig({
            open: false,
            isLoading: false,
            isCheckoutLoading: false,
            cartId: "",
            merchant: {},
          })
        ),
        yield put(customerCartSetCart([])),
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
      ]);
    }

    yield put(customerCartSetConfig({ isCheckoutLoading: false }));
  } catch (error: any) {
    yield all([
      yield put(customerCartSetConfig({ isCheckoutLoading: false })),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);

    // only required for apple pay
    action?.event?.complete("fail");
  }
}

function* customerCartFetchTaxesHandler(action: any): any {
  try {
    const response = yield call(getCustomerTaxes, action?.payload);

    if (response?.success) {
      yield put(customerCartSetTaxes(response?.data));
    }
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
  }
}

export default function* watchCustomerCart() {
  yield takeLatest(CUSTOMER_CART_FETCH_SAGA, customerCartFetchHandler);
  yield takeLatest(CUSTOMER_CART_BUY_SAGA, customerCartBuyHandler);
  yield takeLatest(CUSTOMER_CART_SAVE_SAGA, customerCartSaveHandler);
  yield takeLatest(
    CUSTOMER_CART_FETCH_TAXES_SAGA,
    customerCartFetchTaxesHandler
  );
}
