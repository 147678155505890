import Button from "components/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Checkbox from "@mui/material/Checkbox";
import MuiInput from "@mui/material/Input";
import { styled } from "@mui/material/styles";
import { Dialog, DialogTitle } from "components/Dialog";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import { useMergeState } from "utils/custom-hooks";
import { useEffect } from "react";

const Input = styled(MuiInput)`
  width: 42px;
`;

type Props = {
  open: boolean;
  onClose: () => void;
  onSave: (payload: any) => void;
  selectedPlanRollover: any;
};

const PlanRolloverType = {
  NONE: "NONE",
  UPTO_X_CREDITS: "UPTO_X_CREDITS",
  INFINITE: "INFINITE",
};

export default function CreateRolloverDialog(props: Props) {
  const { open, onClose, onSave, selectedPlanRollover } = props;

  const [state, setState] = useMergeState({
    name: "",
    type: "",
    uptoCredits: 0,
    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        ...state.errors,
        [event.target.name]: false,
      },
    });
  };

  const handleChangePlanRolloverType = (type: string) => {
    let updatedType = type;

    if (state.type === type) {
      updatedType = "";
    }
    setState({
      type: updatedType,
      errors: {
        ...state.errors,
        type: false,
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.name) {
      payload = { ...payload, name: true };
      isValid = false;
    }

    if (!state.type) {
      payload = { ...payload, type: true };
      isValid = false;
    }

    if (state.type === PlanRolloverType.UPTO_X_CREDITS && !state.uptoCredits) {
      payload = { ...payload, uptoCredits: true };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleSave = () => {
    if (!isFormValid()) {
      return;
    }

    const payload = { ...state };

    delete payload.errors;

    if (selectedPlanRollover?._id) {
      payload.id = selectedPlanRollover._id;
    }

    onSave(payload);
  };

  useEffect(() => {
    if (selectedPlanRollover?._id) {
      setState({
        name: selectedPlanRollover?.name,
        type: selectedPlanRollover?.type,
        uptoCredits: selectedPlanRollover?.uptoCredits,
      });
    }
  }, []);

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Create Plan Rollover</DialogTitle>

      <DialogContent dividers>
        <div className="my-4">
          <TextField
            fullWidth
            label="PLAN ROLLOVER NAME"
            variant="outlined"
            name="name"
            value={state.name}
            onChange={handleChange}
            required
            error={state?.errors?.name}
            InputLabelProps={{
              shrink: true,
              disableAnimation: true,
            }}
            autoComplete="off"
          />

          {state?.errors?.name && <ErrorMessage message="Name is required" />}
        </div>

        <div className="my-8">
          <div className="font-semibold mb-2">Plan Rollover Structure</div>

          <div className="">
            <Checkbox
              color="secondary"
              checked={state.type === PlanRolloverType.NONE}
              onChange={() =>
                handleChangePlanRolloverType(PlanRolloverType.NONE)
              }
            />

            <span>Not at all</span>
          </div>

          <div className="">
            <Checkbox
              color="secondary"
              checked={state.type === PlanRolloverType.UPTO_X_CREDITS}
              onChange={() =>
                handleChangePlanRolloverType(PlanRolloverType.UPTO_X_CREDITS)
              }
            />

            <span>Upto X credits</span>

            {state.type === PlanRolloverType.UPTO_X_CREDITS && (
              <div className="ml-10">
                <span className="text-sm mr-4">Please enter credits here</span>

                <Input
                  name="uptoCredits"
                  value={state.uptoCredits}
                  onChange={handleChange}
                  autoFocus
                  inputProps={{
                    type: "number",
                    style: {
                      fontSize: 18,
                    },
                  }}
                  onKeyPress={(event: any) => {
                    const regex = new RegExp("^[0-9]");

                    const key = String.fromCharCode(
                      !event.charCode ? event.which : event.charCode
                    );

                    if (!regex.test(key)) {
                      event.preventDefault();
                      return false;
                    }
                  }}
                />

                {state?.errors?.uptoCredits && (
                  <ErrorMessage message="Please enter credits" />
                )}
              </div>
            )}
          </div>

          <div className="">
            <Checkbox
              color="secondary"
              checked={state.type === PlanRolloverType.INFINITE}
              onChange={() =>
                handleChangePlanRolloverType(PlanRolloverType.INFINITE)
              }
            />

            <span>Credits accumulate indefinitely</span>
          </div>

          {state?.errors?.type && (
            <ErrorMessage message="Please select an option" />
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <div className="my-2">
          <Button
            label="Save"
            color="info"
            onClick={handleSave}
            style={{
              borderRadius: 4,
              fontSize: 14,
              color: "#FFFFFF",
              height: 40,
            }}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
