import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import { MESSAGE_SEVERITY } from "utils/constants";
import { appSetError } from "app/state/actions";
import { merchantAuthResetPasswordSaga } from "../state/actions";

export default function MerchantResetPasswordContainer() {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((store: any) => store?.merchantAuth);

  const navigate = useNavigate();

  // not using searchParams because '+' sign in email is being converted to blank space
  const email = window?.location?.search?.split("email=")[1] || null;

  const [state, setState] = useMergeState({
    newPassword: "",
    confirmNewPassword: "",
    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state?.newPassword) {
      payload = { newPassword: "Please enter your new password", ...payload };
      isValid = false;
    }

    if (state?.newPassword && state?.newPassword.length < 8) {
      payload = {
        newPassword: "Please enter atleast 8 characters",
        ...payload,
      };
      isValid = false;
    }

    if (!state?.confirmNewPassword) {
      payload = {
        confirmNewPassword: "Please confirm your new password",
        ...payload,
      };
      isValid = false;
    }

    if (state?.confirmNewPassword && state?.confirmNewPassword.length < 8) {
      payload = {
        confirmNewPassword: "Please enter atleast 8 characters",
        ...payload,
      };
      isValid = false;
    }

    if (
      state?.newPassword &&
      state?.confirmNewPassword &&
      state?.newPassword !== state?.confirmNewPassword
    ) {
      payload = {
        confirmNewPassword: "New and confirmed password does not match",
        ...payload,
      };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleResetPassword = () => {
    if (!isFormValid()) {
      return;
    }

    if (!email) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: "Something went wrong, please contact support.",
        })
      );
      return;
    }

    dispatch(
      merchantAuthResetPasswordSaga(
        {
          email,
          newPassword: state?.newPassword,
          confirmNewPassword: state?.confirmNewPassword,
        },
        navigate
      )
    );
  };

  return (
    <div className="w-full min-h-screen flex justify-center items-center bg-red-light">
      <div className="bg-white opacity-90 rounded-lg w-11/12 md:w-3/5 lg:w-2/5 pt-5 pb-10 pl-10 pr-10 md:pl-20 md:pr-20 flex items-center mt-10 mb-10">
        <div className="w-full">
          <div className="text-grey text-2xl">Reset Your Password</div>

          <div className="mt-10">
            <div>
              <TextField
                fullWidth
                type="password"
                label="NEW PASSWORD"
                variant="outlined"
                name="newPassword"
                value={state.newPassword}
                onChange={handleChange}
                required
                error={state?.errors?.newPassword}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
              />

              {state?.errors?.newPassword && (
                <ErrorMessage message={state?.errors?.newPassword} />
              )}
            </div>

            <div className="mt-5">
              <TextField
                fullWidth
                type="password"
                label="CONFIRM NEW PASSWORD"
                variant="outlined"
                name="confirmNewPassword"
                value={state.confirmNewPassword}
                onChange={handleChange}
                required
                error={state?.errors?.confirmNewPassword}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
              />

              {state?.errors?.confirmNewPassword && (
                <ErrorMessage message={state?.errors?.confirmNewPassword} />
              )}
            </div>

            <div className="flex items-center justify-end text-grey text-xs mt-4">
              <span>Back to </span>
              <Link to="/m/login" className="ml-1 underline w-fit">
                Login
              </Link>
            </div>

            <div className="mt-10">
              <Button
                label="Reset Password"
                color="secondary"
                onClick={handleResetPassword}
                style={{
                  borderRadius: 10,
                  fontSize: 16,
                  color: "#FFFFFF",
                  height: 50,
                }}
                fullWidth
                loaderButton
                loadingPosition="center"
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
