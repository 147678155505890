import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  getMerchantPlans,
  getMerchantPlanById,
  createMerchantPlan,
  updateMerchantPlan,
  getMerchantPlanSubscribers,
  inviteSubscriber,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  MERCHANT_PLANS_FETCH_SAGA,
  MERCHANT_PLANS_CREATE_SAGA,
  MERCHANT_PLANS_UPDATE_SAGA,
  MERCHANT_PLANS_SUBSCRIBERS_FETCH_SAGA,
  MERCHANT_PLANS_FETCH_SELECTED_PLAN_SAGA,
  MERCHANT_PLANS_INVITE_SUBSCRIBER_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  merchantPlansSetIsLoading,
  merchantPlansSetPlans,
  merchantPlansSetSelectedPlan,
  merchantPlansSetPlanSubscribers,
  merchantPlansSetInviteSubscriberDialogConfig,
} from "./actions";

function* merchantPlansFetchHandler(): any {
  try {
    yield put(merchantPlansSetIsLoading(true));

    const response = yield call(getMerchantPlans, { type: "ALL" });

    if (response?.success) {
      yield put(merchantPlansSetPlans(response?.data));
    }

    yield put(merchantPlansSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantPlansSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantPlansFetchSelectedPlanHandler(action: any): any {
  try {
    yield put(merchantPlansSetIsLoading(true));

    const response = yield call(getMerchantPlanById, action?.payload);

    if (response?.success) {
      yield put(merchantPlansSetSelectedPlan(response?.data));
    }

    yield put(merchantPlansSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantPlansSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantPlansCreateHandler(action: any): any {
  try {
    yield put(merchantPlansSetIsLoading(true));

    const response = yield call(createMerchantPlan, action.payload);

    if (response?.success) {
      action.navigate("/plans");
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantPlansSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantPlansSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantPlansUpdateHandler(action: any): any {
  try {
    yield put(merchantPlansSetIsLoading(true));

    const response = yield call(updateMerchantPlan, action.payload);

    if (response?.success) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantPlansSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantPlansSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantPlansSubscribersFetchHandler(action: any): any {
  try {
    yield put(merchantPlansSetIsLoading(true));

    const response = yield call(getMerchantPlanSubscribers, action.payload);

    if (response?.success) {
      yield put(merchantPlansSetPlanSubscribers(response?.data?.subscribers));
    }

    yield put(merchantPlansSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantPlansSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantPlansInviteSubscriberHandler(action: any): any {
  try {
    yield put(
      merchantPlansSetInviteSubscriberDialogConfig({ isLoading: true })
    );

    const response = yield call(inviteSubscriber, action.payload);

    if (response?.success) {
      yield all([
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
        yield put(
          merchantPlansSetInviteSubscriberDialogConfig({
            open: false,
            isLoading: false,
          })
        ),
      ]);
    }
  } catch (error: any) {
    yield all([
      yield put(
        merchantPlansSetInviteSubscriberDialogConfig({ isLoading: false })
      ),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantPlans() {
  yield takeLatest(MERCHANT_PLANS_FETCH_SAGA, merchantPlansFetchHandler);
  yield takeLatest(
    MERCHANT_PLANS_FETCH_SELECTED_PLAN_SAGA,
    merchantPlansFetchSelectedPlanHandler
  );
  yield takeLatest(MERCHANT_PLANS_CREATE_SAGA, merchantPlansCreateHandler);
  yield takeLatest(MERCHANT_PLANS_UPDATE_SAGA, merchantPlansUpdateHandler);
  yield takeLatest(
    MERCHANT_PLANS_SUBSCRIBERS_FETCH_SAGA,
    merchantPlansSubscribersFetchHandler
  );
  yield takeLatest(
    MERCHANT_PLANS_INVITE_SUBSCRIBER_SAGA,
    merchantPlansInviteSubscriberHandler
  );
}
