import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { nanoid } from "nanoid";
import Chip from "@mui/material/Chip";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { consumerOrdersFetchOrdersSaga } from "./state/actions";
import { formatDate } from "utils/date";
import { PaymentType } from "enums/Payments";
import { OrderType } from "enums/orders";
import { humanizeSchedule } from "enums/PaymentSchedules";

export default function ConsumerOrdersContainer() {
  const dispatch = useDispatch();

  const { isLoading, orders } = useSelector(
    (store: any) => store?.consumerOrders
  );

  const orderHasOneTimePayment = (cart: any) =>
    cart?.some(
      (elem: any) => elem.price?.purchaseType === PaymentType.ONE_TIME
    );

  const orderHasSubscription = (cart: any) =>
    cart?.some(
      (elem: any) => elem.price?.purchaseType === PaymentType.SUBSCRIPTION
    );

  const orderHasBMPL = (cart: any) =>
    cart?.some(
      (elem: any) => elem.price?.purchaseType === PaymentType.BUY_MORE_PAY_LESS
    );

  const handleViewReceipt = (orderId: string) => {
    window.open(`/orders/receipt?id=${orderId}`, "_blank");
  };

  const groupByItems = (items: any) => {
    const products: any = [];

    items.forEach((item: any) => {
      let quantity = 0;

      const index = products.findIndex(
        (product: any) => product?._id === item?._id
      );

      if (index !== -1) {
        products[index].quantity += 1;
      } else {
        quantity = 1;
        products.push({ item, quantity });
      }
    });

    return products;
  };

  useEffect(() => {
    dispatch(consumerOrdersFetchOrdersSaga({ type: OrderType.PURCHASE }));
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div className="py-4 flex justify-center">
          <div className="w-11/12 lg:w-2/3">
            <div className="text-grey text-2xl font-semibold">Orders</div>

            <div className="mt-10">
              {orders?.map((order: any) => (
                <div
                  key={order?._id}
                  className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 mt-8"
                >
                  <div className="flex flex-col md:flex-row justify-between md:items-center">
                    <div className="text-grey text-xl font-semibold">
                      {order?.merchant?.businessName}
                    </div>

                    <div className="mt-2 md:mt-0">
                      {orderHasOneTimePayment(order?.cart?.items) && (
                        <Chip
                          label="One Time"
                          variant="outlined"
                          sx={{
                            marginRight: 2,
                            fontSize: 12,
                            border: "1px solid",
                          }}
                        />
                      )}

                      {orderHasSubscription(order?.cart?.items) && (
                        <Chip
                          label="Subscription"
                          variant="outlined"
                          sx={{
                            marginRight: 2,
                            fontSize: 12,
                            border: "1px solid",
                          }}
                        />
                      )}

                      {orderHasBMPL(order?.cart?.items) && (
                        <Chip
                          label="BMPL"
                          variant="outlined"
                          sx={{
                            marginRight: 2,
                            fontSize: 12,
                            border: "1px solid",
                          }}
                        />
                      )}
                    </div>
                  </div>

                  <div className="text-grey-2 my-1">
                    <span>{formatDate(order?.createdAt, "ll")}</span>
                    <span> • </span>
                    <span>{order?.cart?.items?.length} items</span>
                  </div>

                  {groupByItems(order?.cart?.items)?.map(
                    (item: any, index: number) => (
                      <div
                        key={`${item?.item?.product?._id}-${nanoid()}`}
                        className="text-grey font-medium"
                      >
                        {!item?.item?.price?.planType ? (
                          <span>
                            {item?.item?.title}{" "}
                            {humanizeSchedule(item?.item?.price?.schedule)} (x
                            {item?.item?.price?.quantity})
                          </span>
                        ) : (
                          <span>
                            {item?.item?.title} (x{item?.quantity})
                            {groupByItems(order?.cart?.items)?.length !==
                              index + 1 && " • "}
                          </span>
                        )}
                      </div>
                    )
                  )}

                  <div className="mt-2 flex justify-between items-center">
                    <div>
                      <Chip label={order?.status} />
                    </div>

                    <div>
                      <Button
                        label="View Receipt"
                        onClick={() => handleViewReceipt(order?._id)}
                        style={{
                          borderRadius: 4,
                          fontSize: 14,
                          color: "#FFFFFF",
                          height: 40,
                          width: 140,
                          background: "#6A8566",
                        }}
                        loaderButton
                        loadingPosition="center"
                        loading={isLoading}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
