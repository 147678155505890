import axios from "axios";

import { CHERRY_TOKEN } from "utils/constants";
import { getPlatformType } from "utils/common";

const getHeaders = () => ({
  "x-access-token": localStorage.getItem(CHERRY_TOKEN) || "",
  "x-platform-type": getPlatformType(),
});

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 120000,
});

const Exception = (message: string) => {
  const error: any = new Error(message);

  error.success = false;

  return error;
};

const processError = (error: any) => {
  if (error?.response?.data) {
    // client received an error response (5xx, 4xx)

    throw Exception(error.response.data?.message);
  }

  if (error?.request) {
    // client never received a response, or request never left
    throw Exception("It's not you, it's us, want to give it another try?");
  }

  // anything else
  throw Exception("Oops! Something went wrong.");
};

// ---------- USERS ----------

export const getUserInfo = async () => {
  try {
    const response = await API.get("/users/info", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateUserInfo = async (payload: any) => {
  try {
    const response = await API.put("/users", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const sendPasswordResetInstructions = async (payload: any) => {
  try {
    const response = await API.post(
      "/users/send-password-reset-instructions",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const checkResetPasswordLinkExpiry = async (payload: any) => {
  try {
    const response = await API.post(
      "/users/check-reset-password-link-expiry",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const resetPassword = async (payload: any) => {
  try {
    const response = await API.put("/users/reset-password", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// SIGNIN FOR CONSUMERS AND MERCHANT MEMBERS
export const signin = async (payload: any) => {
  try {
    const response = await API.post("/users/signin/email", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANTS ----------

export const getMerchantDetails = async (params: {
  mId?: string;
  subdomain?: string;
}) => {
  try {
    const response = await API.get("/merchant", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const onboardMerchant = async (payload: any) => {
  try {
    const response = await API.post("/merchant/onboard", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const setupMerchant = async (payload: any) => {
  try {
    const response = await API.post("/merchant/setup", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT BUSINESS PROFILE ----------

export const getMerchantProfile = async () => {
  try {
    const response = await API.get("/merchant/profile", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantProfile = async (payload: any) => {
  try {
    const response = await API.put("/merchant/profile", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const listDemoMerchantAccounts = async () => {
  try {
    const response = await API.get("/merchant/demo", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createDemoMerchantAccount = async (payload: any) => {
  try {
    const response = await API.post("/merchant/demo", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT MEMBERS ----------

export const signupMerchantMember = async (payload: any) => {
  try {
    const response = await API.post("/merchant/members/signup", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const signinMember = async (merchantId: string, payload: any) => {
  try {
    const response = await API.post(
      `/merchant/${merchantId}/members/signin`,
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantMembers = async () => {
  try {
    const response = await API.get("/merchant/members", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const inviteMerchantMember = async (payload: any) => {
  try {
    const response = await API.post("/merchant/members/invite", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT STATS ----------

export const getMerchantStats = async () => {
  try {
    const response = await API.get("/merchant/stats", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const merchantBusinessPageViewed = async () => {
  try {
    const response = await API.post(
      "/merchant/viewed",
      {},
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT SETTINGS ----------

export const merchantUpdateSettingsGeneral = async (payload: any) => {
  try {
    const response = await API.put("/merchant/settings/general", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const merchantUpdateProcessingFeesType = async (payload: {
  processingFeesType: string;
}) => {
  try {
    const response = await API.put("/merchant/processing-fees-type", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const merchantUpdateSettingsMenu = async (payload: any) => {
  try {
    const response = await API.put("/merchant/settings/menu", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT PLAN ROLLOVERS ----------

export const getMerchantPlanRollovers = async () => {
  try {
    const response = await API.get("/merchant/rollovers", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createMerchantPlanRollover = async (payload: any) => {
  try {
    const response = await API.post("/merchant/rollovers", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantPlanRollovers = async (payload: any) => {
  try {
    const response = await API.put("/merchant/rollovers", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT PLANS ----------

export const getMerchantPlans = async (params: { type: string }) => {
  try {
    const response = await API.get("/merchant/plans", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantPlanById = async (planId: string) => {
  try {
    const response = await API.get(`/merchant/plans/plan/${planId}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createMerchantPlan = async (payload: any) => {
  try {
    const response = await API.post("/merchant/plans", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantPlan = async (payload: any) => {
  try {
    const response = await API.put("/merchant/plans", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const hideMerchantPlan = async (payload: {
  id: string;
  hidden: boolean;
}) => {
  try {
    const response = await API.put("/merchant/plans/hide", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantPlanSubscribers = async (params: any) => {
  try {
    const response = await API.get("/merchant/plans/subscribers", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const inviteSubscriber = async (payload: any) => {
  try {
    const response = await API.post(
      "/merchant/plans/subscribers/invite",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantPlanDiscounts = async () => {
  try {
    const response = await API.get("/merchant/plans/discounts", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantPlanDiscounts = async (payload: {
  individualYearlyDiscountPercentage: number;
  teamPlanDiscounts: Array<any>;
}) => {
  try {
    const response = await API.put("/merchant/plans/discounts", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT PRODUCTS ----------

export const getMerchantProducts = async () => {
  try {
    const response = await API.get("/merchant/products", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantProductsColumns = async () => {
  try {
    const response = await API.get("/merchant/products/columns", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantProductById = async (productId: string) => {
  try {
    const response = await API.get(`/merchant/products/${productId}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createMerchantProduct = async (payload: any) => {
  try {
    const response = await API.post("/merchant/products", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantProduct = async (payload: any) => {
  try {
    const response = await API.put("/merchant/products", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const cloneMerchantProduct = async (payload: any) => {
  try {
    const response = await API.post("/merchant/products/clone", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const deleteMerchantProduct = async (params: any) => {
  try {
    const response = await API.delete("/merchant/products", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantProductUnits = async (payload: any) => {
  try {
    const response = await API.put("/merchant/products/units", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantProductOrder = async (payload: any) => {
  try {
    const response = await API.put("/merchant/products/order", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT PRODUCTS MODIFIERS ----------

export const getMerchantProductsModifiers = async () => {
  try {
    const response = await API.get("/merchant/products/modifiers", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantProductModifierById = async (id: string) => {
  try {
    const response = await API.get(`/merchant/products/modifiers/${id}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createMerchantProductModifier = async (payload: any) => {
  try {
    const response = await API.post("/merchant/products/modifiers", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantProductModifier = async (payload: any) => {
  try {
    const response = await API.put("/merchant/products/modifiers", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT PRODUCTS DISCOUNT RULES ----------

export const getMerchantProductsDiscountRules = async () => {
  try {
    const response = await API.get("/merchant/products/discount-rules", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantProductDiscountRuleById = async (id: string) => {
  try {
    const response = await API.get(`/merchant/products/discount-rules/${id}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createMerchantProductDiscountRule = async (payload: any) => {
  try {
    const response = await API.post(
      "/merchant/products/discount-rules",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantProductDiscountRule = async (payload: any) => {
  try {
    const response = await API.put(
      "/merchant/products/discount-rules",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT COMMUNITY CART ----------

export const getMerchantMasterCart = async () => {
  try {
    const response = await API.get("/merchant/community-cart/master", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantCommunityCart = async () => {
  try {
    const response = await API.get("/merchant/community-cart", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const addMerchantCommunityCart = async (payload: any) => {
  try {
    const response = await API.post("/merchant/community-cart/add", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT ORDERS ----------

export const getMerchantOrders = async (params: any) => {
  try {
    const response = await API.get("/merchant/orders", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantOrderDetails = async (id: string) => {
  try {
    const response = await API.get(`/merchant/orders/${id}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createMerchantOrder = async (payload: any) => {
  try {
    const response = await API.post("/merchant/orders", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createMerchantRepeatOrder = async (payload: any) => {
  try {
    const response = await API.post("/merchant/orders/repeat", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantOrder = async (orderId: string, payload: any) => {
  try {
    const response = await API.put(`/merchant/orders/${orderId}`, payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT ALERTS ----------

export const getMerchantAlerts = async (params: { type: string }) => {
  try {
    const response = await API.get("/merchant/alerts", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT MENU ----------

export const getMerchantMenu = async (params: {
  id?: string;
  subdomain?: string;
}) => {
  try {
    const response = await API.get("/merchant/menu", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT INVITATIONS ----------

export const getMerchantInvitations = async () => {
  try {
    const response = await API.get("/merchant/invitations", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const sendMerchantInvitation = async (payload: any) => {
  try {
    const response = await API.post("/merchant/invitations", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT CUSTOMERS ----------

export const getMerchantCustomers = async () => {
  try {
    const response = await API.get("/merchant/customers", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- MERCHANT DISCOUNTS ----------

export const getMerchantDiscounts = async () => {
  try {
    const response = await API.get("/merchant/discounts", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getMerchantDiscountById = async (discountId: string) => {
  try {
    const response = await API.get(`/merchant/discounts/${discountId}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createMerchantDiscount = async (payload: any) => {
  try {
    const response = await API.post("/merchant/discounts", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const updateMerchantDiscount = async (payload: any) => {
  try {
    const response = await API.put("/merchant/discounts", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const inviteCustomerMerchantDiscount = async (payload: any) => {
  try {
    const response = await API.post(
      "/merchant/discounts/invite-customer",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- CUSTOMERS ----------

export const signup = async (payload: any) => {
  try {
    const response = await API.post("/users/signup/email", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const generateOTP = async (payload: any) => {
  try {
    const response = await API.post("/users/generate-otp", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// FOR CUSTOMERS SIGN UP AND SIGN IN
export const verifyOTP = async (payload: any) => {
  try {
    const response = await API.post("/users/verify-otp", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerByStripeConnectCustomerId = async (params: {
  stripeConnectCustomerId?: string;
  userId?: string;
  merchantId?: string;
}) => {
  try {
    const response = await API.get("/customer", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createStripeCustomer = async (payload: {
  merchantId: string;
  name: string;
  email: string;
}) => {
  try {
    const response = await API.post("/customer", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerStore = async () => {
  try {
    const response = await API.get("/customer/store", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerStoreById = async (id: string) => {
  try {
    const response = await API.get(`/customer/store/${id}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerPlans = async () => {
  try {
    const response = await API.get("/customer/plans", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerCorporatePlans = async () => {
  try {
    const response = await API.get("/customer/corporate-plans", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const inviteCorporatePlanMembers = async (payload: any) => {
  try {
    const response = await API.post(
      "/customer/corporate-plans/invite",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const inviteCorporatePlanSelf = async (payload: any) => {
  try {
    const response = await API.post(
      "/customer/corporate-plans/invite/self",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const pauseMemberCorporatePlan = async (payload: any) => {
  try {
    const response = await API.put(
      "/customer/corporate-plans/members/pause",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const resumeMemberCorporatePlan = async (payload: any) => {
  try {
    const response = await API.put(
      "/customer/corporate-plans/members/resume",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const cancelMemberCorporatePlan = async (payload: any) => {
  try {
    const response = await API.put(
      "/customer/corporate-plans/members/cancel",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerPlanDiscounts = async (params: { mId: string }) => {
  try {
    const response = await API.put("/customer/plans/discounts", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerCheckoutDetails = async (params: any) => {
  try {
    const response = await API.get("/customer/checkout/details", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerPlanById = async (id: string) => {
  try {
    const response = await API.get(`/customer/plans/${id}`, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerSubscriptionDetails = async (params: {
  subscriptionId: string;
}) => {
  try {
    const response = await API.get("/customer/subscriptions/details", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- CUSTOMER ORDERS ----------

export const getCustomerOrders = async (params: {
  type: string;
  subscriptionId?: string;
}) => {
  try {
    const response = await API.get("/customer/orders", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getCustomerOrderDetails = async (params: { id: string }) => {
  try {
    const response = await API.get("/customer/orders/details", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createCustomerOrder = async (payload: any) => {
  try {
    const response = await API.post("/customer/orders", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- CUSTOMER CART ----------

export const getCustomerCart = async (params: { id: string }) => {
  try {
    const response = await API.get("/customer/cart", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const saveCustomerCart = async (payload: {
  cartId: string;
  cart: any;
}) => {
  try {
    const response = await API.put("/customer/cart", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- PRICES ----------

export const getPrice = async (params: {
  merchantId: string;
  planId: string;
}) => {
  try {
    const response = await API.get("/customer/prices", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- STRIPE ----------

export const createStripeConnectAccount = async () => {
  try {
    const response = await API.post(
      "/payments/account",
      {},
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getStripeConnectAccountLink = async () => {
  try {
    const response = await API.get("/payments/account/link", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const connectSquareAccount = async (payload: { code: string }) => {
  try {
    const response = await API.post("/payments/account/square", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const connectStripeAccount = async (payload: { code: string }) => {
  try {
    const response = await API.post("/payments/account/stripe", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getStripeConnectAccount = async () => {
  try {
    const response = await API.get("/payments/account", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createStripeSetupIntent = async (payload: {
  platformCustomerId: string;
}) => {
  try {
    const response = await API.post("/payments/setup-intent", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const getStripePaymentMethods = async () => {
  try {
    const response = await API.get("/payments/payment-methods", {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const stripeMakePayment = async (payload: {
  merchantId: string;
  planId: string;
  priceId: string;
}) => {
  try {
    const response = await API.post("/payments", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const verifyStripeSetupIntent = async (payload: {
  setupIntentId: string;
  platformCustomerId: string;
  merchantId: string;
  planId: string;
  priceId: string;
}) => {
  try {
    const response = await API.post("/payments/verify", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const addPaymentMethod = async (payload: {
  pmId: string;
  pcId: string;
  isTextToPay?: boolean;
  flow?: string;
}) => {
  try {
    const response = await API.post("/payments/payment-methods", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const confirmAddPaymentMethod = async (payload: {
  setupIntentId: string;
  platformCustomerId: string;
}) => {
  try {
    const response = await API.post(
      "/payments/payment-methods/confirm",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createSubscription = async (payload: {
  merchantId: string;
  planId: string;
  priceId: string;
  platformCustomerId: string;
  paymentMethodId: string;
}) => {
  try {
    const response = await API.post("/customer/subscriptions", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const createBuyMorePayLessSubscription = async (payload: {
  merchantId: string;
  planId: string;
  platformCustomerId: string;
  paymentMethodId: string;
  period: string;
  amount: number;
}) => {
  try {
    const response = await API.post(
      "/customer/buy-more-pay-less/subscriptions",
      payload,
      {
        headers: getHeaders(),
      }
    );
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const pauseStripeSubscription = async (payload: {
  subscriptionId: string;
}) => {
  try {
    const response = await API.put("/customer/subscriptions/pause", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

export const cancelStripeSubscription = async (payload: {
  subscriptionId: string;
}) => {
  try {
    const response = await API.put("/customer/subscriptions/cancel", payload, {
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- CUSTOMER TAXES ----------

export const getCustomerTaxes = async (params: { mId: string }) => {
  try {
    const response = await API.get("/customer/taxes", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};

// ---------- CUSTOMER BOOKINGS ----------

export const getAvailableSlots = async (params: any) => {
  try {
    const response = await API.get("/customer/bookings/slots", {
      params,
      headers: getHeaders(),
    });
    return response.data;
  } catch (error) {
    return processError(error);
  }
};
