import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import Button from "components/Button";
import ConfirmationDialog from "components/ConfirmationDialog";
import { useMergeState } from "utils/custom-hooks";
import { merchantAuthForgotPasswordSendInstructionsSaga } from "../state/actions";

export default function MerchantForgotPasswordContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading } = useSelector((store: any) => store?.merchantAuth);

  const [state, setState] = useMergeState({
    email: "",
    shouldShowConfirmationDialog: false,
    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const handleOpenConfirmationDialog = () => {
    setState({ shouldShowConfirmationDialog: true });
  };

  const handleCloseConfirmationDialog = () => {
    setState({ shouldShowConfirmationDialog: false });
  };

  const handleConfirmPasswordInstructionsSentDialog = () => {
    handleCloseConfirmationDialog();
    navigate("/m/login");
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.email) {
      payload = { email: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleSendInstructions = async () => {
    if (!isFormValid()) {
      return;
    }

    dispatch(
      merchantAuthForgotPasswordSendInstructionsSaga({ email: state?.email })
    );

    handleOpenConfirmationDialog();
  };

  return (
    <div className="w-full min-h-screen flex justify-center items-center bg-red-light">
      <div className="bg-white opacity-90 rounded-lg w-11/12 md:w-3/5 lg:w-2/5 pt-5 pb-10 pl-10 pr-10 md:pl-20 md:pr-20 flex items-center mt-10 mb-10">
        <div className="w-full">
          <div className="text-grey text-2xl">Forgot your password? Relax!</div>

          <div className="mt-10">
            <div>
              <TextField
                fullWidth
                label="EMAIL"
                variant="outlined"
                name="email"
                value={state.email}
                onChange={handleChange}
                required
                error={state?.errors?.email}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
              />

              {state?.errors?.email && (
                <ErrorMessage message="Email is required" />
              )}
            </div>

            <div className="text-grey text-sm mt-4">
              Type the address linked to your account and we&apos;ll send you
              password reset instructions. They might end up in your spam
              folder, so please check there as well.
            </div>

            <div className="mt-10">
              <Button
                label="Send Instructions"
                color="secondary"
                onClick={handleSendInstructions}
                style={{
                  borderRadius: 10,
                  fontSize: 16,
                  color: "#FFFFFF",
                  height: 50,
                }}
                fullWidth
                loaderButton
                loadingPosition="center"
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <ConfirmationDialog
        open={state.shouldShowConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
        onConfirm={handleConfirmPasswordInstructionsSentDialog}
        title="Affirmative!"
        message="If the address you just typed matches one of our accounts, we'll send an email there with instructions to reset your password."
        confirmButtonLabel="Okay"
      />
    </div>
  );
}
