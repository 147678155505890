import moment from "moment-timezone";
import { useSearchParams } from "react-router-dom";
import Spinner from "components/Spinner";
import { useCustomerOrderDetails } from "./useCustomerOrderDetails";
import { formatDate } from "utils/date";
import { nanoid } from "nanoid";
import { humanizeSchedule } from "enums/PaymentSchedules";
import { emailMask, phoneMask } from "utils/string";

export default function ConsumerOrderDetailsContainer() {
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("id") || "";

  const {
    isLoading,
    merchant,
    customer,
    items,
    createdAt,
    taxes,
    subtotal,
    fees,
    total,
  } = useCustomerOrderDetails(orderId);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div className="py-4 flex justify-center">
          <div className="w-11/12 lg:w-2/3">
            <div className="text-grey text-2xl font-semibold mt-4">
              {merchant?.businessName}
            </div>

            <div className="text-grey text-lg font-medium mt-4">
              Thank you! We&apos;ve received your order!
            </div>

            <div className="text-sm mt-4">
              - {formatDate(createdAt, "dddd lll")}
            </div>

            <div className="flex flex-col lg:flex-row justify-between mt-4">
              <div>
                <div className="text-sm">
                  Estimated pickup time: Today at{" "}
                  {formatDate(moment(createdAt).add(5, "minutes"), "h:m A")}
                </div>

                <div className="my-8">
                  <div className="text-grey-2 font-semibold">
                    STORE LOCATION
                  </div>

                  <div className="text-grey text-sm font-normal mt-4">
                    {merchant?.addressLine1}, {merchant?.city},{" "}
                    {merchant?.state} {merchant?.zipcode}
                  </div>
                </div>
              </div>

              <div className="rounded-md p-4 shadow-md lg:w-1/2">
                <div className="text-grey-2 font-semibold">YOUR ORDER</div>

                {items?.map((item: any) => (
                  <div
                    key={`${item?.item?.product?._id}-${nanoid()}`}
                    className="text-grey font-medium mt-4 w-full"
                  >
                    {!item?.item?.price?.planType ? (
                      <div className="flex justify-between items-center">
                        <span>{item?.item?.title} </span>

                        <span>
                          {humanizeSchedule(item?.item?.price?.schedule)} (x
                          {item?.item?.price?.quantity})
                        </span>
                      </div>
                    ) : (
                      <div>
                        <div className="flex justify-between items-center">
                          <span>{item?.item?.title}</span>

                          <div>
                            <div>{item?.quantity}x</div>
                            <div className="mt-1">
                              ${item?.item?.price?.totalAmount}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                <hr className="my-4" />

                <div>
                  <div className="flex justify-between items-center mt-2">
                    <span>Subtotal</span>
                    <span>${parseFloat(String(subtotal)).toFixed(2)}</span>
                  </div>

                  <div className="flex justify-between items-center mt-2">
                    <span>Fees</span>
                    <span>{parseFloat(String(fees)).toFixed(2)}</span>
                  </div>

                  <div className="flex justify-between items-center mt-2">
                    <span>Taxes</span>
                    <span>{parseFloat(String(taxes)).toFixed(2)}</span>
                  </div>

                  <div className="flex justify-between items-center font-semibold mt-2">
                    <span>Order total</span>
                    <span>${parseFloat(String(total)).toFixed(2)}</span>
                  </div>

                  <div className="flex justify-between items-center text-xs text-grey-2 mt-2">
                    <span>Visa ending in 1744 (Exp. 2/2028)</span>
                    <span>-${parseFloat(String(total)).toFixed(2)}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-3/4 lg:w-3/5">
              <div className="text-grey-2 font-semibold mt-8">
                ORDER DETAILS
              </div>

              <div className="grid grid-cols-2 gap-4 text-sm mt-4">
                <span className="font-semibold">Order date</span>

                <span>{formatDate(createdAt, "dddd lll")}</span>
              </div>

              <div className="grid grid-cols-2 gap-4 text-sm mt-4">
                <span className="font-semibold">Order number</span>
                <span>{orderId}</span>
              </div>

              <div className="grid grid-cols-2 gap-4 text-sm mt-4">
                <span className="font-semibold">Your information</span>

                <div>
                  <div>{emailMask(customer?.user?.email)}</div>
                  <div className="mt-2">{phoneMask(customer?.user?.phone)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
