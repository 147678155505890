import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import Button from "components/Button";
import TermsOfServiceDialog from "components/TermsOfServiceDialog";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";
import {
  customerAuthGenerateOTPSaga,
  customerAuthVerifyOTPSaga,
  customerAuthFetchMerchantDetailsSaga,
} from "../state/actions";

export default function CustomerSignupContainer() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const store = searchParams.get("store");

  const corporateId = searchParams.get("corporate");

  const corporatePlanId = searchParams.get("plan");

  const productId = searchParams.get("offer");

  // not using searchParams because '+' sign in email is being converted to blank space
  const email = window?.location?.search?.split("&email=")[1] || null;

  const { isLoading, merchant } = useSelector(
    (store: any) => store?.customerAuth
  );

  const [state, setState] = useMergeState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    shouldVerifyOTP: false,
    resendOTPTimer: 0,

    shouldShowTermsOfServiceDialog: false,

    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.firstName) {
      payload = { firstName: true, ...payload };
      isValid = false;
    }

    if (!state.email) {
      payload = { email: true, ...payload };
      isValid = false;
    }

    if (!state.phone) {
      payload = { phone: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleGenerateOTP = () => {
    setState({ shouldVerifyOTP: true, resendOTPTimer: 30 });

    dispatch(customerAuthGenerateOTPSaga({ phone: state.phone }));
  };

  const handleVerifyOTP = () => {
    if (!isFormValid()) {
      return;
    }

    dispatch(
      customerAuthVerifyOTPSaga(
        {
          firstName: state.firstName,
          lastName: state.lastName,
          email: state.email,
          phone: state.phone,
          otp: state.otp,
          isSignUp: true,
          store,
          corporate: corporateId,
          corporatePlanId,
          productId,
        },
        navigate
      )
    );
  };

  const handleOpenTermsOfServiceDialog = () => {
    if (!isFormValid()) {
      return;
    }

    setState({ shouldShowTermsOfServiceDialog: true });
  };

  const handleCloseTermsOfServiceDialog = () => {
    setState({ shouldShowTermsOfServiceDialog: false });
  };

  const handleAcceptTermsOfServiceDialog = () => {
    handleGenerateOTP();
    handleCloseTermsOfServiceDialog();
  };

  const handleReSendOTP = () => {
    if (state?.resendOTPTimer !== 0) {
      return;
    }

    setState({ resendOTPTimer: 30 });
  };

  useEffect(() => {
    const timerId =
      state.resendOTPTimer > 0
        ? setInterval(
            () => setState({ resendOTPTimer: state.resendOTPTimer - 1 }),
            1000
          )
        : undefined;

    return () => clearInterval(timerId);
  }, [state.resendOTPTimer]);

  useEffect(() => {
    if (store) {
      dispatch(customerAuthFetchMerchantDetailsSaga({ store }));
    }

    if (!corporateId || !email) {
      return;
    }

    setState({
      email,
    });
  }, []);

  return (
    <div className="w-full min-h-screen flex justify-center items-center bg-red-light">
      <div className="bg-white opacity-90 rounded-lg w-11/12 md:w-3/5 lg:w-2/5 pt-5 pb-10 pl-10 pr-10 md:pl-20 md:pr-20 flex items-center mt-10 mb-10">
        <div className="w-full">
          <div className="flex justify-center">
            <img src={Images.CherryLogo} alt="Cherry" className="h-10 my-4" />
          </div>

          {store ? (
            <div className="text-center text-grey text-4xl font-semibold mt-5">
              Join the {merchant?.businessName} community!
            </div>
          ) : (
            <div className="text-grey text-2xl">Register your account</div>
          )}

          {!store && (
            <div className="text-grey text-xs mt-5">
              Already have an account?{" "}
              <Link to="/c/login" className="underline">
                Login here
              </Link>
            </div>
          )}

          <div className="mt-10">
            <div>
              <TextField
                fullWidth
                label="FIRST NAME"
                variant="outlined"
                name="firstName"
                value={state.firstName}
                onChange={handleChange}
                required
                error={state?.errors?.firstName}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
              />

              {state?.errors?.firstName && (
                <ErrorMessage message="First name is required" />
              )}
            </div>

            <div className="mt-5">
              <TextField
                fullWidth
                label="LAST NAME"
                variant="outlined"
                name="lastName"
                value={state.lastName}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
              />
            </div>

            <div className="mt-5">
              <TextField
                fullWidth
                label="EMAIL"
                variant="outlined"
                name="email"
                value={state.email}
                onChange={handleChange}
                required
                error={state?.errors?.email}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                disabled={!!corporateId}
              />

              {state?.errors?.email && (
                <ErrorMessage message="Email is required" />
              )}
            </div>

            <div className="mt-5">
              <TextField
                fullWidth
                label="PHONE"
                variant="outlined"
                name="phone"
                value={state.phone}
                onChange={handleChange}
                required
                error={state?.errors?.phone}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                type="number"
                onWheel={(event: any) => event.target.blur()}
                onInput={(event) => {
                  if (
                    // @ts-ignore
                    event?.target?.value
                  ) {
                    // @ts-ignore
                    event.target.value = Math.max(
                      0,
                      // @ts-ignore
                      parseInt(event.target.value, 10)
                    )
                      .toString()
                      .slice(0, 10);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+1</InputAdornment>
                  ),
                }}
              />

              {state?.errors?.phone && (
                <ErrorMessage message="Phone is required" />
              )}
            </div>

            {state.shouldVerifyOTP && (
              <div className="mt-5">
                <TextField
                  fullWidth
                  label="OTP"
                  variant="outlined"
                  name="otp"
                  value={state.otp}
                  onChange={handleChange}
                  required
                  error={state?.errors?.otp}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  autoComplete="off"
                  type="number"
                  onWheel={(event: any) => event.target.blur()}
                />

                {state?.errors?.otp && (
                  <ErrorMessage message="OTP is required" />
                )}
              </div>
            )}

            {state.shouldVerifyOTP && (
              <div className="text-grey text-sm mt-2 flex justify-end">
                <span
                  className={`${
                    state?.resendOTPTimer === 0
                      ? "underline cursor-pointer"
                      : ""
                  } mr-1`}
                  onClick={handleReSendOTP}
                >
                  Resend OTP
                </span>
                {state?.resendOTPTimer > 1 && (
                  <span className="mr-1">
                    in {state?.resendOTPTimer}{" "}
                    {state?.resendOTPTimer > 1 ? "seconds" : "second"}
                  </span>
                )}
              </div>
            )}

            <div className="mt-10">
              <Button
                label={`${
                  state.shouldVerifyOTP
                    ? "Confirm Account"
                    : store
                    ? "Join"
                    : "Register"
                }`}
                color="secondary"
                onClick={
                  state.shouldVerifyOTP
                    ? handleVerifyOTP
                    : handleOpenTermsOfServiceDialog
                }
                style={{
                  borderRadius: 10,
                  fontSize: 16,
                  color: "#FFFFFF",
                  height: 50,
                }}
                fullWidth
                loaderButton
                loadingPosition="center"
                loading={isLoading}
              />
            </div>
            <div className="text-green-3 text-center mt-10">
              Powered by Cherry
            </div>
          </div>
        </div>
      </div>

      {state.shouldShowTermsOfServiceDialog && (
        <TermsOfServiceDialog
          open={state.shouldShowTermsOfServiceDialog}
          onClose={handleCloseTermsOfServiceDialog}
          onAccept={handleAcceptTermsOfServiceDialog}
        />
      )}
    </div>
  );
}
