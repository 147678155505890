import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "components/Spinner";
import {
  merchantDashboardInitSaga,
  merchantDashboardBusinessPageViewedSaga,
} from "../state/actions";

export default function MerchantDashboardOverviewContainer() {
  const dispatch = useDispatch();

  const {
    user: {
      merchant: { businessName, businessPageViewed, subdomain },
    },
  } = useSelector((store: any) => store?.merchantAuth);

  const {
    isLoading,
    stats: { overall, monthly },
  } = useSelector((store: any) => store?.merchantDashboard);

  const handleRedirectMerchantMenu = () => {
    if (!businessPageViewed) {
      dispatch(merchantDashboardBusinessPageViewedSaga());
    }

    window.open(
      `${process.env.REACT_APP_BASE_URL}/menu?store=${subdomain}`,
      "_blank"
    );
  };

  useEffect(() => {
    dispatch(merchantDashboardInitSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div className="">
          <div className="text-4xl font-semibold text-grey">
            Welcome, {businessName}!
          </div>

          <div className="flex justify-between items-center lg:w-1/2 mt-8">
            <div>
              <div className="text-grey text-xs font-normal">
                Monthly Revenue
              </div>
              <div className="text-2xl text-grey font-semibold">
                ${monthly?.total}
              </div>
            </div>

            <div>
              <div className="text-grey text-xs font-normal">
                Monthly Orders
              </div>
              <div className="text-2xl text-grey font-semibold">
                {monthly?.orders}
              </div>
            </div>

            <div>
              <div className="text-grey text-xs font-normal">
                New Subscriptions
              </div>
              <div className="text-2xl text-grey font-semibold">
                {monthly?.newSubscriptions}
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row">
            <div className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 w-full lg:w-8/12 mt-4">
              <div className="text-xl font-semibold text-grey">
                Revenue Overview
              </div>

              <hr className="my-4" />

              <div className="text-xs font-medium text-grey">MONTHLY</div>

              <div className="grid grid-cols-2 gap-4 items-center">
                <div>
                  <div className="text-xs font-normal text-grey">
                    One Time: ${monthly?.oneTime}
                  </div>

                  <div className="text-xs font-normal text-grey mt-1">
                    Subscription: ${monthly?.subscription}
                  </div>

                  <div className="text-xs font-normal text-grey mt-1">
                    BMPL: ${monthly?.buyMorePayLess}
                  </div>
                </div>

                <div>
                  <div className="text-xs">
                    <span className="text-grey">Active Subscriptions</span>
                    <span className="text-green font-semibold ml-1">
                      {monthly?.activeSubscriptions}
                    </span>
                  </div>

                  <div className="text-xs mt-1">
                    <span className="text-grey">New Subscriptions</span>
                    <span className="text-green font-semibold ml-1">
                      {monthly?.newSubscriptions}
                    </span>
                  </div>

                  <div className="text-xs mt-1">
                    <span className="text-grey">Monthly Revenue</span>
                    <span className="text-green font-semibold ml-1">
                      ${monthly?.total}
                    </span>
                  </div>
                </div>
              </div>

              <hr className="my-4" />

              <div className="text-xs font-medium text-grey">LIFETIME</div>

              <div className="grid grid-cols-2 gap-4 items-center">
                <div>
                  <div className="text-xs font-normal text-grey">
                    One Time: ${overall?.oneTime}
                  </div>

                  <div className="text-xs font-normal text-grey mt-1">
                    Subscription: ${overall?.subscription}
                  </div>

                  <div className="text-xs font-normal text-grey mt-1">
                    BMPL: ${overall?.buyMorePayLess}
                  </div>
                </div>

                <div>
                  <div className="text-xs">
                    <span className="text-grey">Average Order Value</span>
                    <span className="text-green font-semibold ml-1">
                      ${overall?.averageOrderValue}
                    </span>
                  </div>

                  <div className="text-xs mt-1">
                    <span className="text-grey text-left">Total Orders</span>
                    <span className="text-green font-semibold ml-1">
                      {overall?.orders}
                    </span>
                  </div>

                  <div className="text-xs mt-1">
                    <span className="text-grey">Lifetime Revenue</span>
                    <span className="text-green font-semibold ml-1">
                      ${overall?.total}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 w-full md:w-1/3 mt-4 ml-0 md:ml-2">
              <div className="text-3xl font-semibold text-grey mb-3">
                Things You Can Do
              </div>

              <Link
                to="/customers/invitations"
                className="text-grey-2 underline my-1"
              >
                Invite A Customer
              </Link>

              {/* <div className="text-grey-2 underline my-1 cursor-pointer">
                Refer A Business, Get $50 Amazon Gift Card
              </div> */}

              <div
                className="text-grey-2 underline my-1 cursor-pointer"
                onClick={handleRedirectMerchantMenu}
              >
                Check Out Your Business Page
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
