import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// enums
import { UserTypes } from "enums/userTypes";

// merchant routes
import MerchantRoutesContainer from "./merchant";

// customer routes
import ConsumerRoutesContainer from "./consumer";

// containers
import MerchantOnboardingContainer from "containers/merchant/auth/onboarding";
import MerchantSignupContainer from "containers/merchant/auth/signup";
import MerchantLoginContainer from "containers/merchant/auth/login";
import CustomerSignupContainer from "containers/consumer/auth/signup";
import CustomerSigninContainer from "containers/consumer/auth/signin";

// components
import Alert from "components/Alert";
import CustomerCartDrawer from "components/Customer/CartDrawer";

import { appInitSaga } from "../../app/state/actions"; // app actions

const shouldRenderPlatformRoutes = () => {
  let render = true;

  if (
    window.location.pathname.includes("/signup") ||
    window.location.pathname.includes("/login") ||
    window.location.pathname.includes("/onboarding")
  ) {
    render = false;
  }

  return render;
};

const RoutesContainer = () => {
  const dispatch = useDispatch();

  const { platformType } = useSelector((store: any) => store?.app);

  useEffect(() => {
    dispatch(appInitSaga());
  }, []);

  console.log("platformType : ", platformType);

  return (
    <BrowserRouter>
      <Alert />
      <CustomerCartDrawer />

      <Routes>
        {/* merchant */}
        <Route path="/m">
          <Route path="onboarding" element={<MerchantOnboardingContainer />} />
          <Route path="signup" element={<MerchantSignupContainer />} />
          <Route path="login" element={<MerchantLoginContainer />} />
        </Route>

        {/* consumers */}
        <Route path="/c">
          <Route path="signup" element={<CustomerSignupContainer />} />
          <Route path="login" element={<CustomerSigninContainer />} />
        </Route>
      </Routes>

      {shouldRenderPlatformRoutes() && platformType === UserTypes.MERCHANT && (
        <MerchantRoutesContainer />
      )}

      {shouldRenderPlatformRoutes() && platformType !== UserTypes.MERCHANT && (
        <div>
          <ConsumerRoutesContainer />

          <div className="flex justify-center">
            <div className="text-green-3 h-10">Powered by Cherry</div>
          </div>
        </div>
      )}
    </BrowserRouter>
  );
};

export default RoutesContainer;
