import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { nanoid } from "nanoid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import Button from "components/Button";
import ConfirmationDialog from "components/ConfirmationDialog";
import MerchantSelectModifiersDialog from "components/MerchantSelectModifiersDialog";
import ErrorMessage from "components/ErrorMessage";
import { useMergeState } from "utils/custom-hooks";
import { toBase64 } from "utils/common";
import {
  merchantProductsFetchSelectedProductSaga,
  merchantProductsCreateSaga,
  merchantProductsUpdateSaga,
  merchantProductsCloneSaga,
  merchantProductsDeleteSaga,
  merchantProductsDiscountRulesFetchSaga,
} from "../state/actions";

const TIME_PERIODS = [
  {
    label: "Day",
    value: "DAY",
  },
  {
    label: "Week",
    value: "WEEK",
  },
  {
    label: "Month",
    value: "MONTH",
  },
  {
    label: "Ready to ship",
    value: "READY_TO_SHIP",
  },
];

type VariationType = {
  id: number;
  version: number;
  name: string;
  price: number;
};

const getVariationId = () => `#${nanoid()}`;

export default function MerchantProductsCreateContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const productId = searchParams.get("id");

  const forCC = searchParams.get("cc") === "true";

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const { isLoading, product, discountRules } = useSelector(
    (store: any) => store?.merchantProducts
  );

  const [state, setState] = useMergeState({
    title: "",
    description: "",
    images: [],
    variations: [],
    modifiers: [],
    discountRule: "",
    timeUnits: "",
    timePeriod: "",
    showOnMenu: false,

    shouldShowSelectModifiersDialog: false,
    shouldShowConfirmDeleteProductDialog: false,
    isEditMode: false,
    errors: {},
  });

  // true && !true && !false

  const isDisabled =
    state?.isEditMode &&
    !user?.merchant?.allowPushingToSquare &&
    !user?.merchant?.demoAccount;

  const imagesRef = useRef<any>();

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const handleChangeShowOnMenu = (event: any) => {
    setState({ showOnMenu: event?.target?.checked });
  };

  const handleImagesRef = () => {
    if (isDisabled) {
      return;
    }

    imagesRef.current.click();
  };

  const handleChangeImages = async (event: any) => {
    const { files } = event.target;

    if (files.length < 1 || files.length > 3) return;

    setState({
      images: [...files],
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.title) {
      payload = { title: true, ...payload };
      isValid = false;
    }

    if (!state.description) {
      payload = { description: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const wrapVariations = (variations: Array<VariationType>) =>
    variations?.map((variation: VariationType) => ({
      ...variation,
      price: Number(variation?.price),
    }));

  const handleCreateProduct = async () => {
    if (!isFormValid()) {
      return;
    }

    const images = await Promise.all(
      state?.images.map(async (image: any) => {
        if (image?.type) {
          return { base64: await toBase64(image), contentType: image?.type };
        }

        return image;
      })
    );

    const payload: any = {
      title: state?.title,
      description: state?.description,
      images,
      variations: wrapVariations(state?.variations),
      modifiers: state?.modifiers,
    };

    if (!forCC) {
      payload.discountRule = state?.discountRule || undefined;
      payload.timeUnits = state?.timeUnits;
      payload.timePeriod = state?.timePeriod;
      payload.showOnMenu = state?.showOnMenu;
    } else {
      payload.forCC = forCC;
    }

    if (state?.isEditMode) {
      dispatch(
        merchantProductsUpdateSaga({
          id: productId,
          ...payload,
        })
      );
    } else {
      dispatch(merchantProductsCreateSaga(payload, navigate));
    }
  };

  const handleOpenConfirmDeleteProductDialog = () => {
    setState({ shouldShowConfirmDeleteProductDialog: true });
  };

  const handleCloseConfirmDeleteProductDialog = () => {
    setState({ shouldShowConfirmDeleteProductDialog: false });
  };

  const handleAddModifiers = (modifiers: Array<any>) => {
    setState({ modifiers });
  };

  const handleConfirmDeleteProductDialog = () => {
    dispatch(merchantProductsDeleteSaga({ id: productId }, navigate));
    handleCloseConfirmDeleteProductDialog();
  };

  const handleChangeVariation = (event: any, index: number) => {
    const newVariations = [...state?.variations];

    newVariations[index][event?.target?.name] = event?.target?.value;

    setState({
      variations: [...newVariations],
    });
  };

  const handleAddVariation = () => {
    const newVariations = [...state?.variations];

    newVariations.push({
      id: getVariationId(),
      name: "",
      price: 0,
    });

    setState({
      variations: newVariations,
    });
  };

  const handleRemoveVariation = (index: number) => {
    const newVariations = [...state?.variations];

    newVariations.splice(index, 1);

    setState({
      variations: newVariations,
    });
  };

  const getButtonLabel = () => {
    let label = state.isEditMode ? "Save Product" : "Create Product";

    if (forCC) {
      label = `${label} for CC`;
    }

    return label;
  };

  const handleOpenSelectModifiersDialog = () => {
    setState({ shouldShowSelectModifiersDialog: true });
  };

  const handleCloseSelectModifiersDialog = () => {
    setState({ shouldShowSelectModifiersDialog: false });
  };

  const handleCloneProduct = () => {
    dispatch(merchantProductsCloneSaga({ id: productId }, navigate));
  };

  useEffect(() => {
    if (productId) {
      dispatch(merchantProductsFetchSelectedProductSaga(productId));
      setState({ isEditMode: true });
    }

    dispatch(merchantProductsDiscountRulesFetchSaga());
  }, []);

  useEffect(() => {
    if (state?.isEditMode) {
      const payload = {
        title: product?.title,
        description: product?.description,
        images: product?.images,
        variations: product?.variations,
        modifiers: product?.modifiers,
        discountRule: product?.discountRule,
        timeUnits: product?.timeUnits,
        timePeriod: product?.timePeriod,
        showOnMenu: product?.showOnMenu,
      };

      setState({ ...payload });
    } else {
      setState({
        variations: [
          {
            id: getVariationId(),
            name: "Regular",
            price: 0,
          },
        ],
      });
    }
  }, [product]);

  return (
    <div>
      <div>
        <div className="text-4xl font-semibold text-grey mb-4">
          {state.isEditMode ? "Edit product" : "Create a product"}
        </div>

        <div className="w-full lg:w-3/5 my-8">
          <div>
            <div className="text-grey font-medium">
              What do you want to call your product?
            </div>

            <div className="mt-2 mb-2">
              <TextField
                fullWidth
                className="input-text-field"
                placeholder="Product name"
                variant="outlined"
                name="title"
                value={state.title}
                onChange={handleChange}
                required
                InputProps={{
                  style: {
                    height: 40,
                    fontSize: 14,
                  },
                }}
                error={state?.errors?.title}
                disabled={isDisabled}
              />

              {state?.errors?.title && (
                <ErrorMessage message="Title is required" />
              )}
            </div>
          </div>

          <div className="mt-5">
            <div className="text-grey font-medium">
              How would you describe this product?
            </div>

            <div className="mt-2 mb-2">
              <TextField
                fullWidth
                className="input-text-field"
                placeholder="Product description"
                variant="outlined"
                name="description"
                value={state.description}
                onChange={handleChange}
                required
                error={state?.errors?.description}
                multiline
                maxRows={4}
                InputProps={{
                  style: {
                    fontSize: 14,
                  },
                }}
                disabled={isDisabled}
              />

              {state?.errors?.description && (
                <ErrorMessage message="Description is required" />
              )}
            </div>
          </div>

          <div className="mt-5">
            <div className="text-grey font-medium">
              Upload up to three photos
            </div>

            {/* <div className="text-sm text-grey">
              Upload up to 3 images, these help motivate someone to subscribe by
              giving them a clear sense of the product
            </div> */}

            <div className="flex items-center mt-4">
              {state.images.map((elem: any) => {
                const image = elem?.type ? URL.createObjectURL(elem) : elem;
                return (
                  <img
                    key={image}
                    src={image}
                    alt=""
                    width={100}
                    height={100}
                    className="mr-4"
                  />
                );
              })}

              <div
                className="flex justify-center items-center text-grey text-sm font-medium cursor-pointer ml-4 upload-pictures"
                onClick={handleImagesRef}
              >
                <AddIcon /> Upload pictures
              </div>

              <input
                type="file"
                multiple
                accept="image/*"
                className="hidden"
                ref={imagesRef}
                onChange={handleChangeImages}
              />
            </div>
          </div>

          <hr className="my-4" />

          <div>
            <div className="text-grey font-medium">Variations</div>

            <div className="mt-3">
              {state?.variations?.map(
                (variation: VariationType, index: number) => (
                  <div
                    key={variation?.id}
                    className="flex justify-between items-center mt-2"
                  >
                    <div className="w-full md:w-3/4 flex justify-between items-center">
                      <div>
                        <div className="text-grey text-sm">Name</div>

                        <div className="mt-1">
                          <TextField
                            variant="outlined"
                            name="name"
                            value={state?.variations[index]?.name}
                            onChange={(event) =>
                              handleChangeVariation(event, index)
                            }
                            color="secondary"
                            InputProps={{
                              style: {
                                height: 40,
                                borderRadius: 6,
                                fontSize: 14,
                              },
                            }}
                            fullWidth
                            disabled={isDisabled}
                          />
                        </div>
                      </div>

                      <div className="ml-2">
                        <div className="text-grey text-sm">Price</div>

                        <div className="mt-1">
                          <TextField
                            type="number"
                            variant="outlined"
                            name="price"
                            value={state?.variations[index]?.price}
                            onWheel={(event: any) => event.target.blur()}
                            onChange={(event) =>
                              handleChangeVariation(event, index)
                            }
                            color="secondary"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              style: {
                                height: 40,
                                borderRadius: 6,
                                fontSize: 14,
                              },
                            }}
                            fullWidth
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                    </div>

                    {!isDisabled && (
                      <div className="w-1/4 flex justify-end mt-8">
                        {index === state?.variations?.length - 1 ? (
                          <IconButton onClick={handleAddVariation}>
                            <AddIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => handleRemoveVariation(index)}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}

                        {index !== 0 &&
                          index === state?.variations?.length - 1 && (
                            <IconButton
                              onClick={() => handleRemoveVariation(index)}
                              sx={{ ml: 1 }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          )}
                      </div>
                    )}
                  </div>
                )
              )}
            </div>

            <hr className="my-4" />

            <div>
              <div className="text-grey font-medium">Modifiers</div>

              <div className="mt-2 text-sm">
                Add a custom set of modifiers to have customizable options for
                an item at checkout, such as toppings, add-ons, or special
                requests.
              </div>

              <div className="mt-4">
                <Button
                  label="Select Modifiers"
                  color="secondary"
                  onClick={handleOpenSelectModifiersDialog}
                  style={{
                    borderRadius: 4,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 40,
                  }}
                />
              </div>

              <div className="mt-4">
                {state?.modifiers?.map((modifier: any, index: number) => (
                  <div key={modifier._id}>
                    <div>
                      <div className="text-grey font-medium">
                        {index + 1}) {modifier?.title}
                      </div>
                      {modifier?.modifiers?.map(
                        (elem: { name: string }, index: number) => (
                          <span className="text-grey-2 text-sm">
                            {elem?.name}
                            {index + 1 === modifier?.modifiers?.length
                              ? ""
                              : ", "}
                          </span>
                        )
                      )}
                    </div>

                    {index + 1 !== state?.modifiers?.length && (
                      <hr className="my-4" />
                    )}
                  </div>
                ))}
              </div>
            </div>

            {!forCC && (
              <div>
                <hr className="my-4" />

                <div className="w-1/3 my-4">
                  <div className="text-grey font-medium mb-2">
                    Discount Rule
                  </div>

                  <FormControl
                    variant="outlined"
                    className="input-select-field"
                  >
                    <Select
                      variant="outlined"
                      name="discountRule"
                      value={state?.discountRule}
                      onChange={handleChange}
                      color="secondary"
                      required
                    >
                      {discountRules.map((rule: any) => (
                        <MenuItem key={rule?._id} value={rule?._id}>
                          {rule?.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}

            {!forCC && (
              <div>
                <hr className="my-4" />

                <div className="w-1/3 flex items-center my-4">
                  <Checkbox
                    color="secondary"
                    checked={state?.showOnMenu}
                    onChange={handleChangeShowOnMenu}
                  />

                  <div className="text-grey font-medium">Show on menu</div>
                </div>
              </div>
            )}

            {!forCC && user?.merchant?.isB2B && (
              <div>
                <hr className="my-4" />

                <div className="text-grey font-semibold mt-4">Lead time</div>

                <div className="flex justify-between items-center my-8">
                  <div className="w-2/5">
                    <TextField
                      fullWidth
                      type="number"
                      label="TIME UNITS"
                      variant="outlined"
                      name="timeUnits"
                      value={state.timeUnits}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                        disableAnimation: true,
                      }}
                      autoComplete="off"
                    />
                  </div>

                  <div className="w-2/5">
                    <FormControl
                      variant="outlined"
                      className="input-select-field"
                    >
                      <InputLabel
                        shrink
                        disableAnimation
                        className="input-label"
                        required
                      >
                        TIME PERIOD
                      </InputLabel>

                      <Select
                        fullWidth
                        variant="outlined"
                        label="TIME PERIOD"
                        name="timePeriod"
                        value={state?.timePeriod}
                        onChange={handleChange}
                        color="secondary"
                        required
                      >
                        {TIME_PERIODS.map((period: any) => (
                          <MenuItem key={period?.value} value={period?.value}>
                            {period?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            )}

            <hr />

            <div className="flex my-8">
              <Button
                label={getButtonLabel()}
                color="secondary"
                onClick={handleCreateProduct}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  height: 40,
                  color: "#FFFFFF",
                }}
                loaderButton
                loadingPosition="center"
                loading={isLoading}
                disabled={isLoading}
              />

              {state.isEditMode && (
                <div className="flex ml-10">
                  <Button
                    label="Clone Product"
                    color="info"
                    variant="outlined"
                    onClick={handleCloneProduct}
                    style={{
                      borderRadius: 4,
                      fontSize: 14,
                      height: 40,
                      color: "#247B37",
                      borderColor: "#247B37",
                    }}
                    loaderButton
                    loadingPosition="center"
                    loading={isLoading}
                    disabled={isLoading}
                  />

                  <div className="ml-10">
                    <Button
                      color="error"
                      label="Delete Product"
                      variant="outlined"
                      onClick={handleOpenConfirmDeleteProductDialog}
                      style={{
                        borderRadius: 4,
                        fontSize: 14,
                        height: 40,
                      }}
                      loaderButton
                      loadingPosition="center"
                      loading={isLoading}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {state?.shouldShowSelectModifiersDialog && (
        <MerchantSelectModifiersDialog
          open={state?.shouldShowSelectModifiersDialog}
          onClose={handleCloseSelectModifiersDialog}
          onSave={handleAddModifiers}
        />
      )}

      {state?.shouldShowConfirmDeleteProductDialog && (
        <ConfirmationDialog
          open={state?.shouldShowConfirmDeleteProductDialog}
          onClose={handleCloseConfirmDeleteProductDialog}
          onConfirm={handleConfirmDeleteProductDialog}
          title="Delete product?"
          message="Are you sure you want to delete this product? This is an irreversible step."
          confirmButtonLabel="Delete"
        />
      )}
    </div>
  );
}
