import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Spinner from "components/Spinner";

export default function MerchantConnectSquareContainer() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get("code");

    if (code) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/connect/square/callback?code=${code}`;
    }
  }, []);

  return (
    <div className="mt-10 w-full h-screen flex justify-center">
      <Spinner loading />
    </div>
  );
}
