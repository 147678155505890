import { takeLatest, put, all, call } from "redux-saga/effects";
import { getUserInfo } from "api";
import { CHERRY_TOKEN, MESSAGE_SEVERITY } from "utils/constants";
import { getPlatformType, getStoreName } from "utils/common";
import { UserTypes } from "enums/userTypes";
import { APP_INIT_SAGA, APP_LOGOUT_SAGA } from "./types";
import {
  resetAppState,
  appSetStoreName,
  appSetPlatformType,
  appSetError,
} from "./actions"; // app actions
import {
  customerAuthSetCurrentUser,
  customerAuthSetIsLoggedIn,
  customerAuthFetchMerchantDetailsSaga,
} from "containers/consumer/auth/state/actions"; // customer auth actions
import {
  merchantAuthSetCurrentUser,
  merchantAuthSetIsLoggedIn,
} from "containers/merchant/auth/state/actions"; // merchant auth actions

function* appLogoutHandler(): any {
  try {
    localStorage.removeItem(CHERRY_TOKEN);

    yield put(resetAppState());

    window.location.href = "/";
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
  }
}

function* appInitHandler(): any {
  try {
    let storeName = getStoreName();

    let platformType = getPlatformType();

    if (storeName) {
      yield put(customerAuthFetchMerchantDetailsSaga({ store: storeName }));
    }

    if (localStorage.getItem(CHERRY_TOKEN)) {
      const response = yield call(getUserInfo);

      storeName = response?.data?.merchant?.subdomain;

      if (response?.data?.type === UserTypes.CONSUMER) {
        platformType = UserTypes.CONSUMER;

        yield all([
          yield put(customerAuthSetCurrentUser({ ...response.data })),
          yield put(customerAuthSetIsLoggedIn(true)),
        ]);
      } else {
        platformType = UserTypes.MERCHANT;

        yield all([
          yield put(merchantAuthSetCurrentUser({ ...response.data })),
          yield put(merchantAuthSetIsLoggedIn(true)),
        ]);
      }
    }

    yield all([
      yield put(appSetStoreName(storeName)),
      yield put(appSetPlatformType(platformType)),
    ]);
  } catch (error: any) {
    yield all([
      put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
      appLogoutHandler(),
    ]);
  }
}

export default function* watchApp() {
  yield takeLatest(APP_INIT_SAGA, appInitHandler);
  yield takeLatest(APP_LOGOUT_SAGA, appLogoutHandler);
}
