import React from "react";
import { useMergeState } from "utils/custom-hooks";
import { getCustomerOrderDetails, getCustomerTaxes } from "api";
import {
  getSubtotal,
  getTaxes,
  getProcessingFees,
  getCheckoutAmount,
} from "utils/common";
import { ProcessingFeesType } from "enums/Payments";

const groupByItems = (items = []) => {
  const products: any = [];

  items.forEach((item: any) => {
    let quantity = 0;

    const index = products.findIndex(
      (product: any) => product?._id === item?._id
    );

    if (index !== -1) {
      products[index].quantity += 1;
    } else {
      quantity = 1;
      products.push({ item, quantity });
    }
  });

  return products;
};

export const useCustomerOrderDetails = (orderId: string) => {
  const [state, setState] = useMergeState({
    isLoading: false,
    merchant: {},
    customer: {},
    items: [],
    createdAt: "",
    taxes: 0,
    subtotal: 0,
    fees: 0,
    total: 0,
  });

  const init = async () => {
    try {
      const response = await getCustomerOrderDetails({ id: orderId });

      if (response?.success) {
        const taxesResponse = await getCustomerTaxes({
          mId: response?.data?.merchant?._id,
        });

        const subtotal = getSubtotal(response?.data?.cart?.items);

        const taxes = getTaxes(
          response?.data?.cart?.items,
          taxesResponse?.data
        );

        let fees = 0;

        if (
          response?.data?.merchant?.processingFeesType ===
          ProcessingFeesType.CUSTOMER
        ) {
          fees = getProcessingFees(response?.data?.cart?.items);
        }

        const total = getCheckoutAmount(
          response?.data?.cart?.items,
          taxesResponse?.data,
          response?.data?.merchant?.processingFeesType
        );

        setState({
          merchant: response?.data?.merchant,
          customer: response?.data?.customer,
          items: groupByItems(response?.data?.cart?.items),
          createdAt: response?.data?.createdAt,
          taxes,
          subtotal,
          fees,
          total,
        });
      }
    } catch (error: any) {
      console.error("error : ", error);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return {
    isLoading: state.isLoading,
    merchant: state.merchant,
    customer: state.customer,
    items: state.items,
    createdAt: state.createdAt,
    taxes: state.taxes,
    subtotal: state.subtotal,
    fees: state.fees,
    total: state.total,
  };
};
