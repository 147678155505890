import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  signin,
  signup,
  generateOTP,
  verifyOTP,
  getMerchantDetails,
} from "api";
import { CHERRY_TOKEN, MESSAGE_SEVERITY } from "utils/constants";
import {
  CUSTOMER_AUTH_SIGNUP_SAGA,
  CUSTOMER_AUTH_SIGNIN_SAGA,
  CUSTOMER_AUTH_GENERATE_OTP_SAGA,
  CUSTOMER_AUTH_VERIFY_OTP_SAGA,
  CUSTOMER_AUTH_FETCH_MERCHANT_DETAILS_SAGA,
} from "./types";
import {
  customerAuthSetIsLoading,
  customerAuthSetIsLoggedIn,
  customerAuthSetCurrentUser,
  customerAuthSetMerchantDetails,
} from "./actions";
import { appSetError, appSetPlatformType } from "app/state/actions"; // app actions
import { UserTypes } from "enums/userTypes";

function* customerAuthSignupHandler(action: any): any {
  try {
    yield put(customerAuthSetIsLoading(true));

    const response = yield call(signup, action.payload);

    if (response?.success) {
      const {
        data: { user, token },
      } = response;

      localStorage.setItem(CHERRY_TOKEN, token);

      yield all([
        yield put(customerAuthSetIsLoggedIn(true)),
        yield put(customerAuthSetCurrentUser({ ...user })),
        yield put(appSetPlatformType(UserTypes.CONSUMER)),
      ]);

      action.navigate("/");
    }

    yield put(customerAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerAuthsigninHandler(action: any): any {
  try {
    yield put(customerAuthSetIsLoading(true));

    const response = yield call(signin, action.payload);

    if (response?.success) {
      const {
        data: { user, token },
      } = response;

      localStorage.setItem(CHERRY_TOKEN, token);

      yield all([
        yield put(customerAuthSetIsLoggedIn(true)),
        yield put(customerAuthSetCurrentUser({ ...user })),
        yield put(appSetPlatformType(UserTypes.CONSUMER)),
      ]);

      action.navigate("/");
    }

    yield put(customerAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerAuthGenerateOTPHandler(action: any): any {
  try {
    yield put(customerAuthSetIsLoading(true));

    const response = yield call(generateOTP, action.payload);

    if (response?.success) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(customerAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerAuthVerifyOTPHandler(action: any): any {
  try {
    yield put(customerAuthSetIsLoading(true));

    const response = yield call(verifyOTP, action?.payload);

    if (response?.success) {
      const {
        data: { user, token, redirectUrl = null },
      } = response;

      localStorage.setItem(CHERRY_TOKEN, token);

      yield all([
        yield put(customerAuthSetIsLoggedIn(true)),
        yield put(customerAuthSetCurrentUser({ ...user })),
        yield put(appSetPlatformType(UserTypes.CONSUMER)),
      ]);

      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        action.navigate("/");
      }
    }

    yield put(customerAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerAuthFetchMerchantDetailsHandler(action: any): any {
  try {
    const response = yield call(getMerchantDetails, action?.payload);

    if (response?.success) {
      yield put(customerAuthSetMerchantDetails(response?.data));
    }
  } catch (error: any) {
    yield all([
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchCustomerAuth() {
  yield takeLatest(CUSTOMER_AUTH_SIGNUP_SAGA, customerAuthSignupHandler);
  yield takeLatest(CUSTOMER_AUTH_SIGNIN_SAGA, customerAuthsigninHandler);
  yield takeLatest(
    CUSTOMER_AUTH_GENERATE_OTP_SAGA,
    customerAuthGenerateOTPHandler
  );
  yield takeLatest(CUSTOMER_AUTH_VERIFY_OTP_SAGA, customerAuthVerifyOTPHandler);
  yield takeLatest(
    CUSTOMER_AUTH_FETCH_MERCHANT_DETAILS_SAGA,
    customerAuthFetchMerchantDetailsHandler
  );
}
